import React from "react";
import { InputAdornment, TextField } from "@material-ui/core";

interface TextInputProps {
  id: string;
  label: string;
  value: string;
  onChange?: (value: string) => void;
  onBlur?: (
    event?: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => void;
  error?: string;
  prefix?: string;
  className?: string;
}

export const TextInput = (props: TextInputProps) => {
  return (
    <TextField
      id={props.id}
      label={props.label}
      variant="outlined"
      fullWidth
      value={props.value}
      onBlur={props.onBlur}
      error={!!props.error}
      helperText={props.error}
      disabled={!props.onChange}
      onChange={(event) =>
        props.onChange && props.onChange(event.target.value as string)
      }
      InputProps={
        props.prefix
          ? {
              startAdornment: (
                <InputAdornment position={`start`}>
                  {props.prefix}
                </InputAdornment>
              ),
            }
          : undefined
      }
      className={props.className}
    />
  );
};
