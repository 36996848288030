import React from "react";
import {
  Box,
  Button,
  Checkbox,
  CircularProgress,
  Divider,
  Grid,
  Link,
  Modal,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  Tooltip,
} from "@material-ui/core";
import { makeStyles, Theme } from "@material-ui/core/styles";
import { AttachFile } from "@material-ui/icons";
import { useContext, useState } from "react";
import { Utils } from "../../data/util";
import DeclarationApi, { DeclarationDataItem } from "../../data/declaration";
import { SimpleUserItem } from "../../data/user";
import { SnackbarContext } from "../SnackbarProvider";
import { compareDesc } from "date-fns/esm";
import { Edit, Delete } from "@material-ui/icons";
import { useSelector } from "react-redux";
import { Account } from "../../data/principal";
import { RootState } from "../../redux/reducers";

type UserAction = "" | "approve" | "reject" | "update";

export enum TableTypes {
  PENDING = "PENDING",
  PROCESSED = "PROCESSED",
  VIEWALL = "VIEWALL",
}

const animationTableFlipTimeInMs = 800;

const animationFadeoutTimeInMs: number = 500;

const useStyles = makeStyles((theme: Theme) => ({
  "@keyframes rotate-div": {
    "0%": {
      transform: "perspective(120px) rotateY(0deg)",
      "-webkit-transform": "perspective(120px) rotateY(0deg)",
    },
    "100%": {
      transform: "perspective(120px) rotateY(-89.9deg)",
      "-webkit-transform": "perspective(120px) rotateY(-89.9deg)",
    },
  },
  "@keyframes rotate-div-back": {
    "0%": {
      transform: "perspective(120px) rotateY(89.9deg)",
      "-webkit-transform": "perspective(120px) rotateY(89.9deg)",
    },
    "100%": {
      transform: "perspective(120px) rotateY(0deg)",
      "-webkit-transform": "perspective(120px) rotateY(0deg)",
    },
  },
  rotatingContainer: {
    WebkitAnimation: `$rotate-div ${animationTableFlipTimeInMs / 1000}s ${
      theme.transitions.easing.easeInOut
    }`,
    animation: `$rotate-div ${animationTableFlipTimeInMs / 1000}s ${
      theme.transitions.easing.easeInOut
    }`,
  },
  rotatingContainerBack: {
    WebkitAnimation: `$rotate-div-back ${animationTableFlipTimeInMs / 1000}s ${
      theme.transitions.easing.easeInOut
    }`,
    animation: `$rotate-div-back ${animationTableFlipTimeInMs / 1000}s ${
      theme.transitions.easing.easeInOut
    }`,
  },

  allProcessedBlock: {
    border: "2px dashed #bbb",
    textAlign: "center",
    minHeight: "100px",
    justifyContent: "center",
    alignItems: "center",
  },

  attachmentButton: {
    WebkitTransform: "rotate(45deg)",
    msTransform: "rotate(45deg)",
    transform: "rotate(45deg)",
    cursor: "pointer",
    opacity: "50%",
    "&:hover": {
      opacity: "100%",
    },
  },
  updateButton: {
    cursor: "pointer",
    opacity: "50%",
    "&:hover": {
      opacity: "100%",
    },
  },
  linkStyle: {
    cursor: "pointer",
    textDecoration: "none",
    color: "none",
  },
  blackToGreyButton: {
    backgroundColor: "#000000",
    color: "#FFFFFF",
    "&:hover": {
      backgroundColor: "#666666",
    },
  },
  bottomPart: {
    borderTop: "none",
  },
  divider: {
    minWidth: "1200px",
  },
  handledRow: {
    opacity: 0,
    transition: "opacity 2.5s",
  },
  horizontalChildren: {
    display: "flex",
    justifyContent: "space-between",
  },
  invisibleRow: {
    maxHeight: "0px",
  },
  noWrap: {
    whiteSpace: "nowrap",
  },
  spinner: {
    marginLeft: "50%",
    marginTop: "20%",
  },
  tableContainer: {
    maxHeight: "700px",
  },
  topPart: {
    borderBottom: "1px dashed #bbb",
  },
  whiteToBlackButton: {
    marginLeft: "15px",
    "&:hover": {
      backgroundColor: "#000000",
      color: "#FFFFFF",
    },
  },

  animatedItem: {
    animation: `$myEffect ${animationFadeoutTimeInMs}ms ${theme.transitions.easing.easeInOut}`,
  },
  animatedItemExiting: {
    animation: `$myEffectExit ${animationFadeoutTimeInMs}ms ${theme.transitions.easing.easeInOut}`,
    opacity: 0,
  },
  "@keyframes myEffect": {
    "0%": {
      opacity: 0,
    },
    "100%": {
      opacity: 1,
    },
  },
  "@keyframes myEffectExit": {
    "0%": {
      opacity: 1,
    },
    "100%": {
      opacity: 0,
    },
  },

  "@keyframes fadeoutElement": {
    "0%": {
      transform: "opacity 1",
      "-webkit-transform": "opacity 1",
    },
    "100%": {
      transform: "opacity 0",
      "-webkit-transform": "opacity 0",
    },
  },

  fadeOut: {
    WebkitAnimation: `$fadeoutElement ${animationFadeoutTimeInMs / 1000}s ${
      theme.transitions.easing.easeInOut
    }`,
    animation: `$fadeoutElement ${animationFadeoutTimeInMs / 1000}s ${
      theme.transitions.easing.easeInOut
    }`,
  },
}));

interface TableProps {
  aUsers: SimpleUserItem[];
  aDeclarations: DeclarationDataItem[];
  aTableType: TableTypes;
  aListRef?: React.RefObject<HTMLDivElement>;
  aPreviousScrollDiff: React.MutableRefObject<number>;
}

export const DeclarationTable = (props: TableProps) => {
  const classes = useStyles();
  const { showInfo, showError } = useContext(SnackbarContext);

  const [selectedDeclarations, setSelectedDeclarations] = useState<string[]>(
    []
  );

  const account = useSelector<RootState>(
    (state) => state.auth.currentAccount
  ) as Account | null;

  const sortedDeclarations = props.aDeclarations.sort(
    (a: DeclarationDataItem, b: DeclarationDataItem) => {
      const dateA = Utils.parseDateTime(a.submissionDate, a.submissionTime);
      const dateB = Utils.parseDateTime(b.submissionDate, b.submissionTime);
      if (dateA === null) {
        return -1;
      }
      if (dateB === null) {
        return 1;
      }
      return compareDesc(dateA, dateB);
    }
  );

  const [currentAction, setCurrentAction] = useState<UserAction>("");

  const [willProcessAll, setWillProcessAll] = useState(false);

  const [users] = useState<SimpleUserItem[]>(props.aUsers);

  const [unapprovedDeclarations, setUnapprovedDeclarations] = useState<
    DeclarationDataItem[]
  >(props.aTableType === TableTypes.PENDING ? sortedDeclarations : []);

  const [processedDeclarations, setProcessedDeclarations] = useState<
    DeclarationDataItem[]
  >(props.aTableType === TableTypes.PROCESSED ? sortedDeclarations : []);

  const [userDeclarations] = useState<DeclarationDataItem[]>(
    props.aTableType === TableTypes.VIEWALL ? sortedDeclarations : []
  );

  const shorthandVatType = (vatType: string): string => {
    let shorthand: string = "";
    for (let i = 0; i < vatType.length; i++) {
      if (!isNaN(parseInt(vatType[i]))) {
        shorthand += vatType[i];
      }
    }
    shorthand += "%";
    return shorthand;
  };

  const handleAction = (action: UserAction): void => {
    let toRemove: string[] = [];

    if (selectedDeclarations.length > 0) {
      setCurrentAction(action);
      if (selectedDeclarations.length === unapprovedDeclarations.length) {
        setWillProcessAll(true);
      }
      selectedDeclarations.forEach((declarationId) => {
        (action === "approve"
          ? DeclarationApi.approveDeclaration({
              declarationId: declarationId,
            })
          : DeclarationApi.rejectDeclaration({ declarationId: declarationId })
        )
          .then((res) => {
            toRemove.push(res);
          })
          .finally(() => {
            setTimeout(() => {
              setUnapprovedDeclarations(
                unapprovedDeclarations.filter(
                  (unapprovedDeclaration) =>
                    !toRemove.find((id) => id === unapprovedDeclaration.id)
                )
              );
              setSelectedDeclarations([]);
              setCurrentAction("");
              setTimeout(() => {
                setWillProcessAll(false);
              }, animationTableFlipTimeInMs + 100);
            }, animationTableFlipTimeInMs - 100);
          });
      });
    } else {
      showError("No declarations were selected");
    }
  };

  const deleteDeclaration = (declarationId: string) => {
    DeclarationApi.deleteDeclaration(declarationId)
      .then((res) => {
        if (res) {
          showInfo("Declaration deleted successfully!");
          setTimeout(function () {
            window.location.reload();
          }, 3000);
        } else {
          showError(
            "Something went wrong while deleting this declaration, please try again!"
          );
        }
      })
      .catch((err) => {
        console.log(err);
        showError(
          "Something went wrong while deleting this declaration, please try again!"
        );
      });
  };

  const getBorderClass = (
    declaration: DeclarationDataItem,
    partIndex?: number
  ): string => {
    return declaration.parts.length > 1
      ? partIndex === 0
        ? classes.topPart
        : classes.bottomPart
      : "";
  };

  const getRowClass = (declarationId: string): string => {
    if (
      unapprovedDeclarations.filter(
        (declaration) => declaration.id === declarationId
      ).length === 0
    ) {
      return classes.invisibleRow;
    }
    if (currentAction === "approve" || currentAction === "reject") {
      if (selectedDeclarations.includes(declarationId)) {
        return classes.animatedItemExiting;
      }
    }
    return "";
  };

  const showTable = (): boolean =>
    props.aTableType === TableTypes.PROCESSED
      ? true
      : props.aTableType === TableTypes.VIEWALL
      ? true
      : unapprovedDeclarations.length > 0;

  const getTableContainerClass = (): string =>
    props.aTableType === TableTypes.PROCESSED
      ? classes.tableContainer
      : `${classes.tableContainer} ${
          willProcessAll ? classes.rotatingContainer : ``
        }`;

  return (
    <div>
      <Modal
        open={currentAction === "update"}
        style={{ width: "100%", height: "100%" }}
      >
        <CircularProgress className={classes.spinner} />
      </Modal>
      {showTable() ? (
        <>
          <Box>
            <Grid container item sm={12}>
              <Grid item sm={9}>
                <Typography variant="subtitle1">{`${
                  props.aTableType === TableTypes.PENDING
                    ? "PENDING"
                    : props.aTableType === TableTypes.PROCESSED
                    ? "PROCESSED"
                    : "VIEW ALL"
                } EXPENSE DECLARATIONS`}</Typography>
              </Grid>
              <Grid container item justifyContent="flex-end" sm={3}>
                {props.aTableType === TableTypes.PROCESSED && (
                  <Link
                    onClick={() => {
                      if (currentAction === "") {
                        setCurrentAction("update");
                        DeclarationApi.updateStatusses().then(() => {
                          DeclarationApi.getProcessedDeclarations().then(
                            (res) => {
                              setProcessedDeclarations(res);
                              setCurrentAction("");
                            }
                          );
                        });
                      }
                    }}
                    style={{
                      color: "#0085c2",
                      alignSelf: "flex-end",
                      textDecoration: "underline",
                      cursor: "pointer",
                    }}
                  >
                    Update payments
                  </Link>
                )}
              </Grid>
            </Grid>
          </Box>
          <Divider />
          <Box>
            <Grid
              container
              alignItems="flex-start"
              justifyContent="flex-start"
              spacing={2}
            >
              <Grid container item sm={12}>
                <Grid container item>
                  <TableContainer
                    className={getTableContainerClass()}
                    onScroll={() => {
                      if (props.aListRef) {
                        if (props.aListRef.current) {
                          props.aPreviousScrollDiff.current =
                            props.aListRef.current.scrollHeight -
                            props.aListRef.current.scrollTop;
                        }
                      }
                    }}
                    ref={props.aListRef}
                  >
                    <Table stickyHeader aria-label="sticky table">
                      <TableHead>
                        <TableRow>
                          {props.aTableType === TableTypes.PENDING && (
                            <TableCell className={classes.noWrap}>
                              <Checkbox
                                checked={
                                  selectedDeclarations.length ===
                                  unapprovedDeclarations.length
                                }
                                disabled={currentAction !== ""}
                                onChange={(e, value) => {
                                  if (value === true) {
                                    setSelectedDeclarations(
                                      unapprovedDeclarations.reduce(
                                        (list, declaration) => {
                                          list.push(declaration.id);
                                          return list;
                                        },
                                        [] as string[]
                                      )
                                    );
                                  } else {
                                    setSelectedDeclarations([]);
                                  }
                                }}
                              />
                            </TableCell>
                          )}
                          <TableCell className={classes.noWrap}></TableCell>
                          {(props.aTableType === TableTypes.PROCESSED ||
                            props.aTableType === TableTypes.VIEWALL) && (
                            <TableCell className={classes.noWrap}>
                              Status
                            </TableCell>
                          )}
                          {(props.aTableType === TableTypes.PROCESSED ||
                            props.aTableType === TableTypes.VIEWALL) && (
                            <TableCell className={classes.noWrap}>
                              Payment date
                            </TableCell>
                          )}
                          <TableCell className={classes.noWrap}>
                            Reimburse
                          </TableCell>

                          {props.aTableType !== TableTypes.VIEWALL && (
                            <TableCell className={classes.noWrap}>
                              Name
                            </TableCell>
                          )}

                          <TableCell className={classes.noWrap}>Date</TableCell>
                          <TableCell className={`${classes.noWrap}`}>
                            Type of purchase
                          </TableCell>
                          <TableCell className={classes.noWrap} align="right">
                            <div className={classes.noWrap}>Amount</div>
                            <div className={classes.noWrap}>(ex VAT)</div>
                          </TableCell>
                          <TableCell
                            className={`${classes.noWrap}`}
                            align="right"
                          >
                            VAT %
                          </TableCell>
                          <TableCell
                            className={`${classes.noWrap}`}
                            align="right"
                          >
                            <div className={classes.noWrap}>VAT</div>
                            <div className={classes.noWrap}>amount</div>
                          </TableCell>
                          <TableCell className={classes.noWrap} align="right">
                            Total
                          </TableCell>
                          <TableCell className={classes.noWrap}>ID</TableCell>
                          <TableCell className={classes.noWrap}>
                            Remark
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {(props.aTableType === TableTypes.PENDING
                          ? unapprovedDeclarations
                          : props.aTableType === TableTypes.PROCESSED
                          ? processedDeclarations
                          : userDeclarations
                        ).map((declaration) =>
                          declaration.parts.map((part, partIndex) => (
                            <TableRow
                              key={part.yukiId}
                              className={`
                      ${getRowClass(declaration.id)}
                      ${getBorderClass(declaration, partIndex)}
                      `}
                            >
                              {props.aTableType === "PENDING" &&
                                partIndex === 0 && (
                                  <TableCell rowSpan={declaration.parts.length}>
                                    <Checkbox
                                      checked={
                                        selectedDeclarations.length > 0 &&
                                        selectedDeclarations.some(
                                          (value) => value === declaration.id
                                        )
                                      }
                                      disabled={currentAction !== ""}
                                      onChange={(event, value) => {
                                        value === true
                                          ? setSelectedDeclarations((state) => [
                                              ...state,
                                              declaration.id,
                                            ])
                                          : setSelectedDeclarations((state) =>
                                              state.filter(
                                                (id) => id !== declaration.id
                                              )
                                            );
                                      }}
                                    />
                                  </TableCell>
                                )}
                              {partIndex === 0 && (
                                <TableCell rowSpan={declaration.parts.length}>
                                  <AttachFile
                                    className={classes.attachmentButton}
                                    style={{ color: "black" }}
                                    onClick={() => {
                                      let newWindow = window.open("", "_blank");
                                      DeclarationApi.getAttachmentDownloadUrl(
                                        declaration.id
                                      ).then((url) => {
                                        newWindow!.location.href = url;
                                      });
                                    }}
                                  ></AttachFile>
                                  {declaration.status === "SUBMITTED" &&
                                  account?.id === declaration.userId ? (
                                    <>
                                      {account?.isAdmin ? (
                                        <a
                                          href={`/admin/updateDeclaration/${declaration.id}`}
                                          style={{
                                            color: "black",
                                            textDecoration: "none",
                                          }}
                                        >
                                          <Edit
                                            className={classes.updateButton}
                                          />
                                        </a>
                                      ) : (
                                        <a
                                          href={`/employee/updateDeclaration/${declaration.id}`}
                                          style={{
                                            color: "black",
                                            textDecoration: "none",
                                          }}
                                        >
                                          <Edit
                                            className={classes.updateButton}
                                          />
                                        </a>
                                      )}

                                      <Delete
                                        className={classes.updateButton}
                                        style={{ color: "black" }}
                                        onClick={() =>
                                          deleteDeclaration(declaration.id)
                                        }
                                      />
                                    </>
                                  ) : null}
                                </TableCell>
                              )}
                              {(props.aTableType === TableTypes.PROCESSED ||
                                props.aTableType === TableTypes.VIEWALL) && (
                                <>
                                  <TableCell
                                    className={`${getBorderClass(
                                      declaration,
                                      partIndex
                                    )} ${classes.noWrap}`}
                                  >
                                    {declaration.status === "REJECTED"
                                      ? "Rejected"
                                      : declaration.status === "SUBMITTED"
                                      ? "Pending"
                                      : part.paymentDate === undefined
                                      ? "Approved"
                                      : "Paid"}
                                  </TableCell>
                                  <TableCell
                                    className={`${getBorderClass(
                                      declaration,
                                      partIndex
                                    )} ${classes.noWrap}`}
                                  >
                                    {part.paymentDate && part.paymentDate}
                                  </TableCell>
                                </>
                              )}
                              <TableCell
                                className={`${getBorderClass(
                                  declaration,
                                  partIndex
                                )} ${classes.noWrap}`}
                              >
                                {declaration.reimburse ? "Yes" : "No"}
                              </TableCell>

                              {props.aTableType !== TableTypes.VIEWALL && (
                                <TableCell
                                  className={`${getBorderClass(
                                    declaration,
                                    partIndex
                                  )} ${classes.noWrap}`}
                                >
                                  {users
                                    .filter(
                                      (user) =>
                                        user.userId === declaration.userId
                                    )
                                    .map((user) => user.name)}
                                </TableCell>
                              )}

                              <TableCell
                                className={`${getBorderClass(
                                  declaration,
                                  partIndex
                                )} ${classes.noWrap}`}
                              >
                                {Utils.viewDateTime(
                                  Utils.parseDateTime(
                                    declaration.submissionDate,
                                    declaration.submissionTime
                                  )
                                ).substr(0, 10)}
                              </TableCell>
                              <TableCell
                                className={`${getBorderClass(
                                  declaration,
                                  partIndex
                                )} ${classes.noWrap}`}
                              >
                                {part.declarationType}
                              </TableCell>
                              <TableCell
                                align="right"
                                className={`${getBorderClass(
                                  declaration,
                                  partIndex
                                )} ${classes.noWrap}`}
                              >{`€ ${Utils.formatAmount(
                                part.amount
                              )}`}</TableCell>
                              <TableCell
                                align="right"
                                className={`${getBorderClass(
                                  declaration,
                                  partIndex
                                )} ${classes.noWrap}`}
                              >
                                {shorthandVatType(part.vatType)}
                              </TableCell>
                              <TableCell
                                align="right"
                                className={`${getBorderClass(
                                  declaration,
                                  partIndex
                                )} ${classes.noWrap}`}
                              >{`€ ${Utils.formatAmount(
                                part.vatAmount
                              )}`}</TableCell>
                              <TableCell
                                align="right"
                                className={`${getBorderClass(
                                  declaration,
                                  partIndex
                                )} ${classes.noWrap}`}
                              >
                                {`€ ${Utils.formatAmount(
                                  part.amount + part.vatAmount
                                )}`}
                              </TableCell>
                              <TableCell
                                className={`${getBorderClass(
                                  declaration,
                                  partIndex
                                )} ${classes.noWrap}`}
                              >
                                {part.yukiId}
                              </TableCell>
                              {declaration.remark ? (
                                declaration.remark.length > 40 ? (
                                  <TableCell
                                    className={`${getBorderClass(
                                      declaration,
                                      partIndex
                                    )} ${classes.noWrap}`}
                                  >
                                    <Tooltip
                                      title={
                                        <>
                                          <Typography color="inherit">
                                            {declaration.remark}
                                          </Typography>
                                        </>
                                      }
                                      placement="top"
                                      arrow
                                    >
                                      <Typography color="inherit">
                                        {declaration.remark
                                          ? declaration.remark.length > 40
                                            ? declaration.remark
                                                .split(" ")
                                                .slice(0, 3)
                                                .join(" ") +
                                              " " +
                                              ". . ."
                                            : declaration.remark
                                          : ""}
                                      </Typography>
                                    </Tooltip>
                                  </TableCell>
                                ) : (
                                  <TableCell
                                    className={`${getBorderClass(
                                      declaration,
                                      partIndex
                                    )} ${classes.noWrap}`}
                                  >
                                    {declaration.remark}
                                  </TableCell>
                                )
                              ) : (
                                ""
                              )}
                            </TableRow>
                          ))
                        )}
                      </TableBody>
                    </Table>
                  </TableContainer>

                  {props.aTableType === TableTypes.PENDING && (
                    <div style={{ paddingTop: "25px" }}>
                      <Button
                        className={classes.blackToGreyButton}
                        onClick={() => handleAction("approve")}
                        disabled={currentAction !== ""}
                      >
                        Approve selected
                      </Button>
                      <Button
                        className={classes.whiteToBlackButton}
                        onClick={() => handleAction("reject")}
                        disabled={currentAction !== ""}
                      >
                        Reject selected
                      </Button>
                    </div>
                  )}
                </Grid>
              </Grid>
            </Grid>
          </Box>
        </>
      ) : (
        <Grid container item md={12}>
          <Grid
            container
            item
            className={`${classes.allProcessedBlock} ${
              willProcessAll ? classes.rotatingContainerBack : ``
            }`}
          >
            <Grid container item direction="column">
              <Typography variant="subtitle2">{`All expense declarations have been processed...`}</Typography>
              <Typography>{`Well done, no more work here!`}</Typography>
            </Grid>
          </Grid>
        </Grid>
      )}
    </div>
  );
};
