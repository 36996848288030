import { useState, useCallback, useMemo } from "react";
import { Utils } from "../data/util";
import * as ibantools from "ibantools";

export interface FieldValidator<T> {
  field: keyof T;
  message: string;
  valid: (value: T[keyof T]) => boolean;
  value?: any;
}

interface Validator<T> {
  validate: <K extends keyof T>(
    field: K,
    message: string,
    valid: (value: T[K]) => boolean,
    value?: any
  ) => void;
  updateMandatoryInputs: <K extends keyof T>(
    inputs: K[],
    existingObject?: boolean
  ) => void;
  get: <K extends keyof T>(field: K) => string | undefined;
  isValid: boolean;
  setValid: <K extends keyof T>(field: K) => void;
  validateMultiple: (validators: FieldValidator<T>[]) => Partial<{ [P in keyof T]: string }>;
}

type ErrorMap<T> = Partial<
  {
    [P in keyof T]: string;
  }
>;

export const useValidator = <T extends any>(
  subject: T,
  mandatoryFields?: (keyof T)[]
): Validator<T> => {
  const [errors, setErrors] = useState<ErrorMap<T>>({});
  const [validatedMandatoryInputs, setValidatedMandatoryInputs] = useState<
    {
      [P in keyof T]: boolean;
    }
  >(
    mandatoryFields
      ? mandatoryFields.reduce((map, item) => {
          map[item] = false;
          return map;
        }, {} as { [P in keyof T]: boolean })
      : ({} as { [P in keyof T]: boolean })
  );

  const validateMultiple = useCallback(
    (validators: FieldValidator<T>[]) => {
      let updatedErrorMap: Partial<{ [P in keyof T]: string }> = {};
      validators.forEach((validator) => {
        let fieldErrorValue =
          validator.value !== undefined
            ? validator.valid(validator.value)
              ? undefined
              : validator.message
            : validator.valid(subject[validator.field])
            ? undefined
            : validator.message;
        if (fieldErrorValue) {
          updatedErrorMap[validator.field] = fieldErrorValue;
        }
      });
      setErrors(() => {
        return updatedErrorMap;
      });
      return updatedErrorMap;
    },
    [subject]
  );

  const validate = useCallback(
    <K extends keyof T>(
      field: K,
      message: string,
      valid: (value: T[K]) => boolean,
      value?: any
    ) => {
      setErrors({
        ...errors,
        [field]:
          value !== undefined
            ? valid(value)
              ? undefined
              : message
            : valid(subject[field])
            ? undefined
            : message,
      });
      setValidatedMandatoryInputs((state) => {
        let newState = state;
        newState[field] =
          value !== undefined ? valid(value) : valid(subject[field]);
        return newState;
      });
    },
    [subject, errors]
  );

  const updateMandatoryInputs = <P extends keyof T>(
    inputs: P[],
    existingObject?: boolean
  ): void => {
    setErrors((state) => {
      let keys = Object.keys(errors);
      keys = keys.filter((value, index) => {
        if (inputs.map((input) => input.toString()).includes(keys[index])) {
          return true;
        } else {
          return false;
        }
      });

      let newState: { [P in keyof T]: string };
      newState = inputs.reduce((map, field) => {
        if (keys.includes(field.toString())) {
          map[field] = state[field]!;
        }
        return map;
      }, {} as { [P in keyof T]: string });

      return newState;
    });

    setValidatedMandatoryInputs((state) => {
      let newState: { [P in keyof T]: boolean };
      newState = inputs.reduce((map, field) => {
        map[field] = existingObject ? true : state[field] ?? false;
        return map;
      }, {} as { [P in keyof T]: boolean });
      return newState;
    });
  };

  const get = useCallback(
    <K extends keyof T>(field: K) => errors[field],
    [errors]
  );

  const isValid = useMemo(() => {
    return (
      Object.values(errors).filter((v) => !!v).length === 0 &&
      Object.values(validatedMandatoryInputs).every((v) => v === true)
    );
  }, [errors, validatedMandatoryInputs]);

  const setValid = <K extends keyof T>(field: K): void => {
    setErrors((state) => {
      state[field] = undefined;
      return state;
    });
    setValidatedMandatoryInputs((state) => {
      state[field] = true;
      return state;
    });
  };

  return {
    validate,
    get,
    isValid,
    updateMandatoryInputs,
    setValid,
    validateMultiple,
  };
};

type Validate = (value: any) => boolean;

export const isNotNull: Validate = (value: any) => value !== null;

export const isValidDate: Validate = (value: string) =>
  value != null && value !== undefined && value.length > 0
    ? !isNaN(Utils.parseDate(value)!.getTime())
    : false;

export const isValidDateOrEmpty: Validate = (value: string) =>
  value == null || value.length === 0 || isValidDate(value);

export const isNotEmpty: Validate = (value: string) =>
  value != null && value.length > 0;

export const isValidHours: Validate = (value: number) => {
  return value !== null && value >= 0 && value <= 40; // Validator for checking the number of contracted hours. Can be zero as zero-hour contracts are now in use.
};
export const isQuadEmail: Validate = (value: string) =>
  value.endsWith("@quadsolutions.nl");

export const isNotZero: Validate = (value: string) =>
  value !== null &&
  value !== undefined &&
  value.length > 0 &&
  parseFloat(value) !== 0;

export const isNotNegative: Validate = (value: string) =>
  value !== null &&
  value !== undefined &&
  value.length > 0 &&
  parseFloat(value) >= 0;

export const isValidIban: Validate = (iban: string) => {
  return ibantools.isValidIBAN(iban);
};

export const isValidIbanOrEmpty: Validate = (value: string) =>
  value == null || value.length === 0 || isValidIban(value);

export const isValidEmail: Validate = (email: string) => {
  if (email !== undefined) {
    let regexp = new RegExp(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );
    return email.length > 0 && regexp.test(String(email).toLowerCase());
  } else return false;
};

export const arrayIsNotEmpty: Validate = (list: any[]) => list.length > 0;

export const isValidPhoneNumber: Validate = (phoneNumber: string) => {
  const regexDutch = /(^\(?([+]31|0031|0)-?6(\s?|-)([0-9]\s{0,3}){8}$)/;
  const regexInternational = /^\+(?:[0-9] ?){6,14}[0-9]$/;
  return regexDutch.test(phoneNumber) || regexInternational.test(phoneNumber);
};

export const isValidPhoneNumberOrEmpty: Validate = (value: string) =>
  value == null || value.length === 0 || isValidPhoneNumber(value);
