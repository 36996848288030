// React
import React from "react";
import ReactDOM from "react-dom";
import { ConnectedRouter } from "connected-react-router";
import App, { AppAuthorize, AppBodyAuthCallback } from "./App";
import Loading from "./component/Authentication/Loading";

// Redux
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/es/integration/react";
import StoreManager from "./redux/store";
import { History } from "./redux/helpers";

// Service worker
import * as serviceWorker from "./serviceWorker";

// Material
import { createTheme, CssBaseline, ThemeProvider } from "@material-ui/core";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";

// Other
import DateFnsUtils from "@date-io/date-fns";
import { Route, Switch } from "react-router-dom";
import { EmailPrefillResponse } from "./page/employee/EmailPrefillResponse";

// Theming
const theme = createTheme({
  palette: {
    secondary: {
      main: "#05324e",
    },
  },
});

const { persistor, store } = StoreManager.configureStore(History);

ReactDOM.render(
  <Provider store={store}>
    <PersistGate loading={<Loading />} persistor={persistor}>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <ConnectedRouter history={History}>
            <Switch>
              <Route path="/authorize">
                <AppAuthorize />
              </Route>
              <Route path="/authCallback">
                <AppBodyAuthCallback />
              </Route>
              <Route path="/prefill/:prefillId">
                <EmailPrefillResponse />
              </Route>
              <App />
            </Switch>
          </ConnectedRouter>
        </MuiPickersUtilsProvider>
      </ThemeProvider>
    </PersistGate>
  </Provider>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
