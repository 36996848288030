import { TimesheetByProject } from "./WeekReport";
import {
  Table,
  TableCell,
  TableHead,
  TableRow,
  Card,
  Tooltip,
  Typography,
} from "@material-ui/core";
import { Utils } from "../../data/util";
import { WeekReportItem, WeekReportTrip } from "../../data/weekReport";
import { Timesheet } from "./Timesheet";
import { createTheme, MuiThemeProvider } from "@material-ui/core/styles";
import { TravelExpenses } from "./TravelExpenses";
import { CheckCircleOutline } from "@material-ui/icons";

//TODO indicator for synced with yuki, check "sentToAdministration" boolean in week report, if true, it's synced

interface HistoryProps {
  timesheet: TimesheetByProject;
  weekreport: WeekReportItem;
  trips: WeekReportTrip[];
  expensePerKm: number;
  reportPeriod: string;
}
const theme = createTheme({
  overrides: {
    MuiTooltip: {
      tooltip: {
        fontSize: "2em",
        backgroundColor: "gray",
      },
    },
  },
});

const HistoryTimeTable = ({
  timesheet,
  weekreport,
  trips,
  expensePerKm,
  reportPeriod,
}: HistoryProps) => {
  return (
    <Card style={{ maxWidth: 700 }}>
      <Table style={{ maxWidth: 700 }} aria-label="spanning table">
        <TableHead>
          <TableRow>
            <TableCell align="left" style={{ width: "10rem" }}>
              {timesheet.userName}
            </TableCell>
            <TableCell align="left">
              {weekreport.sentToAdministration ? (
                <Tooltip title={"Synced in NMBRS"}>
                  <CheckCircleOutline color="primary" />
                </Tooltip>
              ) : (
                <></>
              )}
            </TableCell>
            <TableCell align="left"></TableCell>
            <TableCell align="right">
              <i>{reportPeriod}</i>
            </TableCell>
          </TableRow>

          <TableRow>
            <TableCell align="left">
              Week {timesheet.yearAndWeek.weekNumber}
            </TableCell>
            <TableCell align="left">Business Trips</TableCell>
            <TableCell align="left"></TableCell>
            <MuiThemeProvider theme={theme}>
              <Tooltip
                title={
                  <Typography color="inherit">
                    <TravelExpenses trips={trips} expensePerKm={expensePerKm} />
                  </Typography>
                }
                placement="top"
                arrow
              >
                <TableCell align="right">
                  {timesheet.totalBusinessTripExpenses
                    ? `€ ${Utils.formatAmount(
                        timesheet.totalBusinessTripExpenses
                      )}`
                    : "€ 0,00"}
                </TableCell>
              </Tooltip>
            </MuiThemeProvider>
          </TableRow>

          {timesheet.projectRows?.map((row) => (
            <TableRow>
              <TableCell align="left"></TableCell>
              <TableCell align="left">{row.name}</TableCell>
              <TableCell align="right">{row.hours}</TableCell>
              <TableCell align="right"></TableCell>
            </TableRow>
          ))}

          <TableRow>
            <TableCell align="left"></TableCell>
            <TableCell align="right"></TableCell>
            <MuiThemeProvider theme={theme}>
              <Tooltip
                title={
                  <Typography color="inherit">
                    <Timesheet entries={weekreport.entries || []} />
                  </Typography>
                }
                placement="top"
                arrow
              >
                <TableCell align="right">
                  {timesheet.totalHours > 40 || timesheet.totalHours < 40 ? (
                    <mark>{timesheet.totalHours}</mark>
                  ) : (
                    <>{timesheet.totalHours}</>
                  )}
                </TableCell>
              </Tooltip>
            </MuiThemeProvider>
            <TableCell align="right"></TableCell>
          </TableRow>
        </TableHead>
      </Table>
    </Card>
  );
};

export default HistoryTimeTable;
