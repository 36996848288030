const env = (key: string): string => {
  const value = process.env[key];
  if (!value) {
    throw new Error(`Missing required environment variable: ${key}`);
  }
  return value;
};

export const API_HOST = env("REACT_APP_API_HOST").replace(/\/$/, "");

export const CLIENT_ID = env("REACT_APP_CLIENT_ID");
