import React, { useContext, useEffect, useState } from "react";
import {
  Backdrop,
  Button,
  CircularProgress,
  Collapse,
  Container,
  createStyles,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  List,
  ListItem,
  makeStyles,
  TextField,
  Theme,
  Tooltip,
  Typography,
} from "@material-ui/core";
import { HttpError } from "../../../component/HttpError";
import { Alert } from "@material-ui/lab";
import { TextInput } from "../../../component/TextInput";
import { CheckBoxes } from "../../../component/Checkboxes";
import UserApi, {
  formatRole,
  Gender,
  Role,
  TravelOption,
  UpdateUser,
} from "../../../data/user";
import { DatePicker } from "../../../component/DatePicker";
import { Utils } from "../../../data/util";
import { Dropdown } from "../../../component/Dropdown";
import { nationalities } from "../../../data/nationalities";
import { GoogleMapsPlacefinder } from "../../../component/GoogleMapsPlacefinder";
import { AmountInput } from "../../../component/AmountInput";
import { NumberInput } from "../../../component/NumberInput";
import { SnackbarContext } from "../../../component/SnackbarProvider";
import {
  arrayIsNotEmpty,
  isNotEmpty,
  isNotNegative,
  isQuadEmail,
  isValidDate,
  isValidDateOrEmpty,
  isValidEmail,
  isValidHours,
  isValidIbanOrEmpty,
  isValidPhoneNumberOrEmpty,
  useValidator,
} from "../../../hooks/validator";
import { Prediction } from "../../../data/googleMaps";
import ProjectApi, { ProjectItem, ProjectUser } from "../../../data/project";
import { v4 as uuidv4 } from "uuid";
import { History, LocationState } from "history";

const emptyUser: UpdateUser = {
  userId: "",
  firstName: "",
  initials: "",
  middleName: "",
  lastName: "",
  name: "",
  emailAddress: "",
  dateOfBirth: "",
  nationality: "",
  gender: Gender.NotSpecified,
  institution: "",
  googleAccountActive: undefined,
  iban: "",
  licensePlateNumber: "",
  phoneNumber: "",
  privateEmailAddress: "",
  linkedinUrl: "",
  hardwarePreferences: "",
  plannedHolidays: "",
  employmentStartDate: "",
  employmentEndDate: "",
  travelOption: TravelOption.None,
  mobilityBudgetAmount: "0",
  contractedHours: 0,
  roles: [],
  projects: [],
  googleAddressId: "",
  googleAddress: "",
  googleSessionToken: "",
  employeeNumber: "",
  userAccountStatus: "",
  errorMessage: "",
};

const roleOptions = Object.fromEntries(
  Object.entries(Role).map(([, value]) => [value, formatRole(value)])
);

const mandatoryCreateUserFields: (keyof UpdateUser)[] = [
  "firstName",
  "lastName",
  "roles",
];

const mandatoryUpdateUserFields: (keyof UpdateUser)[] = [
  "userId",
  "firstName",
  "lastName",
  "emailAddress",
  "roles",
];

const mandatoryCreateAdminFields: (keyof UpdateUser)[] = [
  "privateEmailAddress",
];

const mandatoryUpdateAdminFields: (keyof UpdateUser)[] = [];

const mandatoryCreateEmployeeFields: (keyof UpdateUser)[] = [
  "privateEmailAddress",
  "employmentStartDate",
  "contractedHours",
];
const mandatoryUpdateEmployeeFields: (keyof UpdateUser)[] = [
  "dateOfBirth",
  "nationality",
  "googleAddressId",
  "iban",
  "privateEmailAddress",
  "employmentStartDate",
  "contractedHours",
];

const mandatoryInternFields: (keyof UpdateUser)[] = [
  "institution",
];

const getValidationMessage = (field: keyof UpdateUser): string => {
  switch (field) {
    case "userId":
      return "UserId is required";
    case "firstName":
      return "First name must not be empty";
    case "initials":
      return "Initials must not be empty";
    case "lastName":
      return "Last name must not be empty";
    case "emailAddress":
      return "Email address must not be empty and must end with @quadsolutions.nl";
    case "dateOfBirth":
      return "Date of birth is required";
    case "nationality":
      return "Nationality is required";
    case "institution":
      return "Institution is required";
    case "googleAddressId":
      return "Home address is required";
    case "iban":
      return "IBAN must be valid or empty";
    case "phoneNumber":
      return "Phone number must be valid or empty";
    case "privateEmailAddress":
      return "Private email address must not be empty and must be valid";
    case "employmentStartDate":
      return "Employment start date is required";
    case "employmentEndDate":
      return "Employment end date must be valid or empty";
    case "contractedHours":
      return "Contracted hours must be between 0 and 40";
    case "mobilityBudgetAmount":
      return "Mobility budget amount is required";
    case "roles":
      return "A role must be selected";
  }
  return "";
};

const getValidationFunction = (
  field: keyof UpdateUser
): ((value: any) => boolean) => {
  switch (field) {
    case "userId":
      return isNotEmpty;
    case "firstName":
      return isNotEmpty;
    case "initials":
      return isNotEmpty;
    case "lastName":
      return isNotEmpty;
    case "emailAddress":
      return isQuadEmail;
    case "dateOfBirth":
      return isValidDate;
    case "nationality":
      return isNotEmpty;
    case "institution":
      return isNotEmpty;
    case "googleAddressId":
      return isNotEmpty;
    case "iban":
      return isValidIbanOrEmpty;
    case "phoneNumber":
      return isValidPhoneNumberOrEmpty;
    case "privateEmailAddress":
      return isValidEmail;
    case "employmentStartDate":
      return isValidDate;
    case "employmentEndDate":
      return isValidDateOrEmpty;
    case "contractedHours":
      return isValidHours;
    case "mobilityBudgetAmount":
      return isNotNegative;
    case "roles":
      return arrayIsNotEmpty;
  }
  return () => false;
};

const getRequiredFieldsForSettings = (
  newUser: boolean,
  roles: Role[],
  travelOption?: TravelOption
): (keyof UpdateUser)[] => {
  let requiredFields: (keyof UpdateUser)[];
  if (newUser) {
    requiredFields = mandatoryCreateUserFields;
  } else {
    requiredFields = mandatoryUpdateUserFields;
  }

  if (roles.includes(Role.Admin)) {
    if (newUser) {
      requiredFields = requiredFields.concat(mandatoryCreateAdminFields);
    } else {
      requiredFields = requiredFields.concat(mandatoryUpdateAdminFields);
    }
  }
  if (roles.includes(Role.Employee)) {
    if (newUser) {
      requiredFields = requiredFields
        .concat(mandatoryCreateEmployeeFields)
        .concat(
          travelOption === TravelOption.MobilityBudget
            ? "mobilityBudgetAmount"
            : []
        );
    } else {
      requiredFields = requiredFields
        .concat(mandatoryUpdateEmployeeFields)
        .concat(
          travelOption === TravelOption.MobilityBudget
            ? "mobilityBudgetAmount"
            : []
        );
    }
  }

  if (roles.includes(Role.Intern)) {
    requiredFields = requiredFields.concat(mandatoryInternFields);
  }

  return requiredFields;
};

const genderValues = {
  MALE: "Male",
  FEMALE: "Female",
  NOT_SPECIFIED: "Not specified",
};

const travelOptionValues = {
  COMPANY_LEASE_CAR: "Company lease car",
  MOBILITY_BUDGET: "Mobility budget",
  NONE: "None",
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    amountInput: {
      width: "40%",
      marginRight: "5px",
    },

    horizontalChildren: {
      display: "flex",
      flexDirection: "row",
      alignItems: "flex-start",
    },
    whiteToBlackButton: {
      float: "right",
      marginTop: "10px",
      "&:hover": {
        backgroundColor: "#000000",
        color: "#FFFFFF",
      },
    },
    disableButton: {
      marginLeft: "2em",
    },
    backdrop: {
      zIndex: theme.zIndex.drawer + 1,
      color: "#fff",
    },
    classMuiGrid: {
      maxWidth: "100%",
      flexBasis: "100%",
    },
  })
);

interface UserDetailsProps {
  userId: string;
  setRetryAction: (val: () => void) => void;
  setShowHttpError: (value: boolean) => void;
  showHttpError: boolean;
  retryAction?: (() => void) | undefined;
  history: History<LocationState>;
}

export const UserDetails = (props: UserDetailsProps) => {
  const {
    userId,
    setRetryAction,
    setShowHttpError,
    retryAction,
    showHttpError,
    history,
  } = props;

  const newUser = userId === "new";
  const [projectOptions, setProjectOptions] = useState<{
    [key: string]: string;
  }>({});
  const [user, setUser] = useState<UpdateUser>(emptyUser);
  const { showInfo, showError } = useContext(SnackbarContext);
  const [validationErrorVisible, setValidationErrorVisible] = useState(false);
  const [loading, setLoading] = useState<boolean>(true);
  const [disabled, setDisabled] = useState<boolean>(false);

  const classes = useStyles();
  const validator = useValidator<UpdateUser>(
    user,
    getRequiredFieldsForSettings(newUser, [])
  );

  const [popupArchiveUser, setPopupArchiveUser] = useState<boolean>(false);

  const onChangeUserAddress = (value: Prediction | null) => {
    if (value != null) {
      setUser({
        ...user,
        googleAddressId: value.placeId,
        googleAddress: value.description,
      });
      validator.validate(
        "googleAddressId",
        getValidationMessage("googleAddressId"),
        getValidationFunction("googleAddressId"),
        value.placeId
      );
    }
  };
  const onChangeProjects = async (projects: any) => {
    const UserProjects: ProjectUser[] = [];
    var resFilter: ProjectItem[] = [];
    await ProjectApi.getProjects().then((res) => {
      resFilter = res.filter((m) => projects.includes(m.projectId));
    });
    resFilter.forEach((project: ProjectItem) => {
      UserProjects.push({
        projectId: project.projectId,
        userId: user.userId,
        projectName: project.name,
        userName:
          user.firstName + " " + user.middleName
            ? user.middleName + " "
            : "" + user.lastName,
      });
    });
    setUser({ ...user, projects: UserProjects });
  };

  const saveUser = () => {
    setDisabled(true);
    const errorMap = validator.validateMultiple(
      getRequiredFieldsForSettings(
        newUser,
        user.roles ?? [],
        user.travelOption
      ).map((field) => {
        return {
          field,
          message: getValidationMessage(field),
          valid: getValidationFunction(field),
        };
      })
    );

    const isValid = Object.values(errorMap).filter((v) => !!v).length === 0;

    if (!isValid) {
      setValidationErrorVisible(true);
      window.scrollTo(0, 0);
      setDisabled(false);
      return;
    }

    setValidationErrorVisible(false);

    let action = () => {
      setDisabled(true);
      setShowHttpError(false);

      let request = newUser
        ? UserApi.createUser({
            firstName: user.firstName,
            initials: user.initials,
            middleName: user.middleName,
            lastName: user.lastName,
            name: `${user.firstName} ${user.middleName || ""} ${user.lastName}`,
            emailAddress: user.emailAddress,
            googleAccountActive: user.googleAccountActive,
            dateOfBirth: user.dateOfBirth,
            nationality: user.nationality,
            gender: user.gender ?? Gender.NotSpecified,
            institution: user.institution,
            googleAddressId: user.googleAddressId,
            googleAddress: user.googleAddress,
            googleSessionToken: user.googleSessionToken,
            iban: user.iban,
            licensePlateNumber: user.licensePlateNumber,
            phoneNumber: user.phoneNumber,
            privateEmailAddress: user.privateEmailAddress,
            linkedinUrl: user.linkedinUrl,
            hardwarePreferences: user.hardwarePreferences,
            plannedHolidays: user.plannedHolidays,
            employmentStartDate: user.employmentStartDate,
            employmentEndDate: user.employmentEndDate,
            travelOption: user.travelOption ?? TravelOption.None,
            mobilityBudgetAmount: user.mobilityBudgetAmount,
            contractedHours: user.contractedHours ?? 0,
            roles: user.roles,
            projects: user.projects,
          })
        : UserApi.updateUser({
            userId: user.userId,
            firstName: user.firstName,
            initials: user.initials,
            middleName: user.middleName,
            lastName: user.lastName,
            name: `${user.firstName} ${user.middleName || ""} ${user.lastName}`,
            emailAddress: user.emailAddress,
            googleAccountActive: user.googleAccountActive,
            dateOfBirth: user.dateOfBirth,
            nationality: user.nationality,
            gender: user.gender ?? Gender.NotSpecified,
            institution: user.institution,
            googleAddressId: user.googleAddressId,
            googleAddress: user.googleAddress,
            googleSessionToken: user.googleSessionToken,
            iban: user.iban,
            licensePlateNumber: user.licensePlateNumber,
            phoneNumber: user.phoneNumber,
            privateEmailAddress: user.privateEmailAddress,
            linkedinUrl: user.linkedinUrl,
            hardwarePreferences: user.hardwarePreferences,
            plannedHolidays: user.plannedHolidays,
            employmentStartDate: user.employmentStartDate,
            employmentEndDate: user.employmentEndDate,
            travelOption: user.travelOption ?? TravelOption.None,
            mobilityBudgetAmount: user.mobilityBudgetAmount,
            contractedHours: user.contractedHours ?? 0,
            roles: user.roles,
            projects: user.projects,
            userAccountStatus: user.userAccountStatus,
            errorMessage: user.errorMessage,
          });

      request.then(
        () => {
          showInfo(`${newUser ? `Created` : `Updated`} user`);
          console.log(`${newUser ? `Created` : `Updated`} user`);
          history.push("/admin/users");
        },
        (err) => {
          if (err.message !== "Network Error") {
            showError(
              `Failed to ${newUser ? `create` : `updated`} user: \n${
                err.response.data?.message || JSON.stringify(err.response.data)
              }`
            );
          } else {
            setShowHttpError(true);
          }
          setDisabled(false);
        }
      );
    };
    setRetryAction(() => action);
    action();
  };

  const archiveUser = (userId: string) => {
    setDisabled(true);
    let request = UserApi.archiveUser(userId);
    request.then(
      (res) => {
        setDisabled(false);
        if (res === true) {
          showInfo("User archived in the ERP application");
          UserApi.updateUser({
            ...user,
            googleAccountActive: false,
          });
          setTimeout(() => window.location.reload(), 2000);
        } else {
          showError("Failed to archive user");
        }
      },
      (err) => {
        if (err.message !== "Network Error") {
          showError("Failed to archive user");
        } else {
          setShowHttpError(true);
        }
        setDisabled(false);
      }
    );
  };

  useEffect(() => {
    if (loading) {
      ProjectApi.getProjects().then((projects) => {
        const map: any = {};
        projects.forEach((p: ProjectItem) => {
          map[p.projectId] = p.customerName + " - " + p.name;
        });
        setProjectOptions(map);
      });
      if (newUser) {
        setLoading(false);
        setUser({
          ...user,
          googleSessionToken: uuidv4(),
        });
      } else {
        UserApi.getUser(userId).then(
          (res) => {
            setUser({
              userId: res.userId,
              firstName: res.firstName,
              initials: res.initials,
              middleName: res.middleName,
              lastName: res.lastName,
              name: `${res.firstName} ${res.middleName || ""} ${res.lastName}`,
              emailAddress: res.emailAddress,
              institution: res.institution,
              googleAccountActive: res.googleAccountActive,
              googleAddressId: res.homeAddressId,
              googleAddress: res.homeAddress,
              googleSessionToken: uuidv4(),
              contractedHours: res.contractedHours,
              dateOfBirth: res.dateOfBirth,
              employmentEndDate: res.employmentEndDate,
              employmentStartDate: res.employmentStartDate,
              gender: res.gender,
              iban: res.iban,
              licensePlateNumber: res.licensePlateNumber,
              phoneNumber: res.phoneNumber,
              privateEmailAddress: res.privateEmailAddress,
              linkedinUrl: res.linkedinUrl,
              hardwarePreferences: res.hardwarePreferences,
              plannedHolidays: res.plannedHolidays,
              mobilityBudgetAmount: res.mobilityBudgetAmount,
              nationality: res.nationality,
              projects: res.projects,
              roles: res.roles,
              travelOption: res.travelOption,
              employeeNumber: res.employeeNumber,
              userAccountStatus: res.userAccountStatus,
              errorMessage: res.errorMessage,
            });
            validator.updateMandatoryInputs(
              getRequiredFieldsForSettings(
                newUser,
                user.roles ?? [],
                user.travelOption
              ),
              true
            );
            setLoading(false);
          },
          (err) => {
            showError("Failed to load user");
            setLoading(false);
          }
        );
      }
    }
  }, []);

  const isIntern = !!user.roles?.includes(Role.Intern);
  const isNewIntern = isIntern && newUser;

  return (
    <Grid container>
      <Grid container item xs={9} className={classes.classMuiGrid}>
        <Container>
          <HttpError
            open={showHttpError}
            close={() => setShowHttpError(false)}
            retry={() => {
              retryAction && retryAction();
            }}
          />

          <Dialog
            open={popupArchiveUser}
            onClose={() => {
              setPopupArchiveUser(false);
            }}
          >
            <DialogTitle> Archive user? </DialogTitle>
            <DialogContent>
              Warning: Archiving this user will permanently remove their Google
              account and all associated data. This action cannot be undone. Are
              you sure you want to continue?
            </DialogContent>
            <DialogActions>
              <Button
                autoFocus
                color="primary"
                onClick={() => {
                  archiveUser(user.userId);
                }}
              >
                Yes
              </Button>
              <Button
                autoFocus
                color="primary"
                onClick={() => {
                  setPopupArchiveUser(false);
                  setTimeout(() => setDisabled(false), 500);
                }}
              >
                Cancel
              </Button>
            </DialogActions>
          </Dialog>

          {validationErrorVisible && (
            <Alert
              severity="error"
              onClose={() => setValidationErrorVisible(false)}
            >
              Please correct the errors in your form and submit again.
            </Alert>
          )}
          <List>
            <ListItem>
              <TextInput
                id="user-first-name"
                label="First name"
                value={user.firstName}
                onChange={(firstName) => setUser({ ...user, firstName })}
                error={validator.get("firstName")}
                onBlur={() =>
                  validator.validate(
                    "firstName",
                    getValidationMessage("firstName"),
                    getValidationFunction("firstName")
                  )
                }
              />
            </ListItem>

            {!isNewIntern && (
              <>
                {" "}
                <ListItem>
                  <TextInput
                    id="user-initials"
                    label="Initials"
                    value={user.initials}
                    onChange={(initials) => setUser({ ...user, initials })}
                  />
                </ListItem>
                <ListItem>
                  <TextInput
                    id="user-middle-name"
                    label="Middle name (optional)"
                    value={user.middleName ?? ""}
                    onChange={(middleName) => setUser({ ...user, middleName })}
                  />
                </ListItem>{" "}
              </>
            )}
            <ListItem>
              <TextInput
                id="user-last-name"
                label="Last name"
                value={user.lastName}
                onChange={(lastName) => setUser({ ...user, lastName })}
                error={validator.get("lastName")}
                onBlur={() =>
                  validator.validate(
                    "lastName",
                    getValidationMessage("lastName"),
                    getValidationFunction("lastName")
                  )
                }
              />
            </ListItem>
            {!newUser && !isNewIntern && (
              <ListItem>
                <TextInput
                  id="user-email-address"
                  label="first.lastname@quadsolutions.nl"
                  value={user.emailAddress}
                  onChange={
                    newUser
                      ? (emailAddress) => setUser({ ...user, emailAddress })
                      : undefined
                  }
                  error={validator.get("emailAddress")}
                  onBlur={() =>
                    validator.validate(
                      "emailAddress",
                      getValidationMessage("emailAddress"),
                      getValidationFunction("emailAddress")
                    )
                  }
                />
              </ListItem>
            )}
            {isIntern && <ListItem>
              <TextInput
                id="user-institution"
                label="Educational institution"
                value={user.institution}
                onChange={(institution) => setUser({ ...user, institution })}
                error={validator.get("institution")}
                onBlur={() =>
                  validator.validate(
                    "institution",
                    getValidationMessage("institution"),
                    getValidationFunction("institution")
                  )
                }
              />
            </ListItem>}
            <ListItem>
              <CheckBoxes
                id="user-roles"
                label="Role(s)"
                values={user.roles}
                options={roleOptions}
                onChange={(roles: any[]) => {
                  if (
                    roles.includes(Role.Intern) &&
                    !roles.includes(Role.Employee)
                  ) {
                    if (user.roles?.includes(Role.Employee)) {
                      roles = roles.filter((role) => role !== Role.Intern);
                    } else {
                      roles.push(Role.Employee);
                    }
                  }

                  setUser({ ...user, roles });
                  validator.updateMandatoryInputs(
                    getRequiredFieldsForSettings(newUser, roles)
                  );
                  validator.validate(
                    "roles",
                    getValidationMessage("roles"),
                    getValidationFunction("roles"),
                    roles
                  );
                }}
                error={validator.get("roles")}
              />
            </ListItem>
            <Collapse
              in={
                newUser &&
                user.roles?.includes(Role.Admin) &&
                !user.roles?.includes(Role.Employee)
              }
            >
              <ListItem>
                <TextInput
                  id="private-email-address"
                  label="Private email address"
                  value={user.privateEmailAddress ?? ""}
                  onChange={(privateEmailAddress) =>
                    setUser({ ...user, privateEmailAddress })
                  }
                  error={validator.get("privateEmailAddress")}
                  onBlur={(event) =>
                    validator.validate(
                      "privateEmailAddress",
                      getValidationMessage("privateEmailAddress"),
                      getValidationFunction("privateEmailAddress")
                    )
                  }
                />
              </ListItem>
            </Collapse>
            {!isNewIntern && (
              <>
                <ListItem>
                  <CheckBoxes
                    id="working-on-projects"
                    label="Working on project(s)"
                    values={user.projects?.map((m) => m.projectId) ?? []}
                    options={projectOptions}
                    onChange={onChangeProjects}
                    error={validator.get("projects")}
                  />
                </ListItem>
                {!newUser && (
                  <>
                    <ListItem id="user-date-of-birth">
                      <DatePicker
                        label="Date of birth"
                        value={
                          user.dateOfBirth
                            ? Utils.parseDate(user.dateOfBirth)
                            : null
                        }
                        onChange={(date) => {
                          if (date instanceof Date && !isNaN(date?.getTime())) {
                            let dateOfBirth: string = Utils.formatDate(date);
                            setUser({ ...user, dateOfBirth });
                            validator.validate(
                              "dateOfBirth",
                              getValidationMessage("dateOfBirth"),
                              getValidationFunction("dateOfBirth"),
                              dateOfBirth
                            );
                          }
                        }}
                        error={validator.get("dateOfBirth")}
                        onBlur={(event) =>
                          validator.validate(
                            "dateOfBirth",
                            getValidationMessage("dateOfBirth"),
                            getValidationFunction("dateOfBirth")
                          )
                        }
                      />
                    </ListItem>
                    <ListItem id="user-nationality">
                      <Dropdown
                        label="Nationality"
                        value={user.nationality ?? ""}
                        values={nationalities}
                        onChange={(nationality) => {
                          setUser({ ...user, nationality });
                          validator.validate(
                            "nationality",
                            getValidationMessage("nationality"),
                            getValidationFunction("nationality"),
                            nationality
                          );
                        }}
                        error={validator.get("nationality")}
                        onClose={() =>
                          validator.validate(
                            "nationality",
                            getValidationMessage("nationality"),
                            getValidationFunction("nationality"),
                            user.nationality
                          )
                        }
                      />
                    </ListItem>
                    <ListItem id="user-gender">
                      <Dropdown
                        label="Gender"
                        value={user.gender || Gender.NotSpecified}
                        values={genderValues}
                        onChange={(gender) => {
                          setUser({ ...user, gender: gender as Gender });
                        }}
                      />
                    </ListItem>
                    <ListItem id="user-address">
                      <GoogleMapsPlacefinder
                        label="Address"
                        value={
                          user.googleAddressId !== undefined &&
                          user.googleAddress !== undefined
                            ? {
                                placeId: user.googleAddressId,
                                description: user.googleAddress,
                              }
                            : undefined
                        }
                        sessionToken={user.googleSessionToken}
                        onSelectPrediction={onChangeUserAddress}
                        error={validator.get("googleAddressId")}
                        onBlur={() =>
                          validator.validate(
                            "googleAddressId",
                            getValidationMessage("googleAddressId"),
                            getValidationFunction("googleAddressId")
                          )
                        }
                      />
                    </ListItem>
                    <ListItem>
                      <TextInput
                        id="user-iban"
                        label="IBAN"
                        value={user.iban ?? ""}
                        onChange={(iban) => setUser({ ...user, iban })}
                        error={validator.get("iban")}
                        onBlur={(event) =>
                          validator.validate(
                            "iban",
                            getValidationMessage("iban"),
                            getValidationFunction("iban")
                          )
                        }
                      />
                    </ListItem>
                    <ListItem>
                      <TextInput
                        id="user-license-plate-number"
                        label="License plate number (optional)"
                        value={user.licensePlateNumber ?? ""}
                        onChange={(licensePlateNumber) =>
                          setUser({ ...user, licensePlateNumber })
                        }
                      />
                    </ListItem>
                    <ListItem>
                      <TextInput
                        id="user-phone-number"
                        label="Phone number (optional)"
                        value={user.phoneNumber ?? ""}
                        onChange={(phoneNumber) =>
                          setUser({ ...user, phoneNumber })
                        }
                        error={validator.get("phoneNumber")}
                        onBlur={(event) =>
                          validator.validate(
                            "phoneNumber",
                            getValidationMessage("phoneNumber"),
                            getValidationFunction("phoneNumber")
                          )
                        }
                      />
                    </ListItem>
                  </>
                )}
              </>
            )}
            <ListItem>
              <TextInput
                id="private-email-address"
                label="Private email address"
                value={user.privateEmailAddress ?? ""}
                onChange={(privateEmailAddress) =>
                  setUser({ ...user, privateEmailAddress })
                }
                error={validator.get("privateEmailAddress")}
                onBlur={(event) =>
                  validator.validate(
                    "privateEmailAddress",
                    getValidationMessage("privateEmailAddress"),
                    getValidationFunction("privateEmailAddress")
                  )
                }
              />
            </ListItem>
            {!newUser && (
              <>
                <ListItem>
                  <TextInput
                    id="linkedin-url"
                    label="LinkedIn Url (optional)"
                    value={user.linkedinUrl ?? ""}
                    onChange={(linkedinUrl) =>
                      setUser({ ...user, linkedinUrl })
                    }
                  />
                </ListItem>
                <ListItem>
                  <TextField
                    id="hardware-preferences"
                    label="Hardware Preferences (optional)"
                    value={user.hardwarePreferences ?? ""}
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                      setUser({
                        ...user,
                        hardwarePreferences: event.target.value,
                      })
                    }
                    multiline
                    rows={4}
                    placeholder="If you have no preference we'll get you a 32GB DELL/HP equivalent laptop plus standard keyboard, mouse, camera and headset. Also specify a preferred date to pick up the hardware at our office."
                    fullWidth={true}
                    margin="dense"
                    variant="outlined"
                  />
                </ListItem>
                <ListItem>
                  <TextField
                    id="planned-holidays"
                    label="Planned Holidays (optional)"
                    value={user.plannedHolidays ?? ""}
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                      setUser({
                        ...user,
                        plannedHolidays: event.target.value,
                      })
                    }
                    multiline
                    rows={4}
                    placeholder="Specify the dates of the holiday(s) you already planned."
                    fullWidth={true}
                    margin="dense"
                    variant="outlined"
                  />
                </ListItem>
              </>
            )}
            <ListItem id="user-start-date">
              <DatePicker
                label="Employment start date"
                value={
                  user.employmentStartDate
                    ? Utils.parseDate(user.employmentStartDate)
                    : null
                }
                error={validator.get("employmentStartDate")}
                onChange={(date) => {
                  if (date instanceof Date && !isNaN(date?.getTime())) {
                    let employmentStartDate: string = Utils.formatDate(date);
                    setUser({ ...user, employmentStartDate });
                    validator.validate(
                      "employmentStartDate",
                      getValidationMessage("employmentStartDate"),
                      getValidationFunction("employmentStartDate"),
                      employmentStartDate
                    );
                  }
                }}
                onBlur={(event) =>
                  validator.validate(
                    "employmentStartDate",
                    getValidationMessage("employmentStartDate"),
                    getValidationFunction("employmentStartDate")
                  )
                }
              />
            </ListItem>
            <ListItem id="user-end-date">
              <DatePicker
                label="Employment end date (optional)"
                value={
                  user.employmentEndDate
                    ? Utils.parseDate(user.employmentEndDate)
                    : null
                }
                error={validator.get("employmentEndDate")}
                onChange={(date) => {
                  if (date instanceof Date && !isNaN(date?.getTime())) {
                    let employmentEndDate: string = Utils.formatDate(date);
                    setUser({ ...user, employmentEndDate });
                    validator.validate(
                      "employmentEndDate",
                      getValidationMessage("employmentEndDate"),
                      getValidationFunction("employmentEndDate"),
                      employmentEndDate
                    );
                  } else {
                    let employmentEndDate: string = "";
                    setUser({ ...user, employmentEndDate });
                    validator.validate(
                      "employmentEndDate",
                      getValidationMessage("employmentEndDate"),
                      getValidationFunction("employmentEndDate"),
                      employmentEndDate
                    );
                  }
                }}
              />
            </ListItem>

            {!isNewIntern && (
              <>
                {" "}
                <ListItem id="user-travel-option">
                  <Dropdown
                    label="Travel option"
                    value={user.travelOption ?? TravelOption.None}
                    values={travelOptionValues}
                    onChange={(travelOption) => {
                      validator.updateMandatoryInputs(
                        getRequiredFieldsForSettings(
                          newUser,
                          user.roles ?? [],
                          travelOption as TravelOption
                        )
                      );
                      setUser({
                        ...user,
                        travelOption: travelOption as TravelOption,
                        mobilityBudgetAmount:
                          travelOption === TravelOption.MobilityBudget
                            ? user.mobilityBudgetAmount
                            : undefined,
                      });
                    }}
                  />
                </ListItem>
                {user.travelOption === TravelOption.MobilityBudget && (
                  <ListItem>
                    <AmountInput
                      id="mobility-budget-amount"
                      label="Mobility budget amount"
                      value={
                        user.mobilityBudgetAmount
                          ?.toString()
                          .split(".")
                          .join(",") ?? ""
                      }
                      onChange={(mobilityBudgetAmount) =>
                        setUser({ ...user, mobilityBudgetAmount })
                      }
                      error={validator.get("mobilityBudgetAmount")}
                      onBlur={() =>
                        validator.validate(
                          "mobilityBudgetAmount",
                          getValidationMessage("mobilityBudgetAmount"),
                          getValidationFunction("mobilityBudgetAmount"),
                          user.mobilityBudgetAmount
                        )
                      }
                      prefix="€"
                    />
                  </ListItem>
                )}
                <ListItem id="user-contracted-hours">
                  <NumberInput
                    label="Contracted hours"
                    value={user.contractedHours || 0}
                    onChange={(contractedHours) => {
                      // Check whether updated field is empty before updating user
                      if (!contractedHours) {
                        contractedHours = 0;
                      }
                      setUser({ ...user, contractedHours });
                    }}
                    error={validator.get("contractedHours")}
                    onBlur={() =>
                      validator.validate(
                        "contractedHours",
                        getValidationMessage("contractedHours"),
                        getValidationFunction("contractedHours")
                      )
                    }
                  />
                </ListItem>
              </>
            )}

            {user.userId && (
              <ListItem>
                <TextInput
                  id="employee-number"
                  label="Employee number"
                  value={user.employeeNumber ?? ""}
                />
              </ListItem>
            )}
            <ListItem>
              <Button
                id="user-save-button"
                variant="contained"
                color="primary"
                onClick={saveUser}
                disabled={disabled}
              >
                Save
              </Button>
              {/* {!newUser && (
                <Button
                  onClick={disableUser}
                  disabled={disabled}
                  className={classes.disableButton}
                >
                  Disable
                </Button>
              )} */}
              {user.userAccountStatus === "DEACTIVATED" && (
                <Tooltip
                  title={
                    <Typography color="inherit">
                      Click this button to archive this user.
                    </Typography>
                  }
                  placement="right"
                  arrow
                >
                  <Button
                    onClick={() => {
                      setPopupArchiveUser(true);
                    }}
                    disabled={disabled}
                    className={classes.disableButton}
                  >
                    Archive user
                  </Button>
                </Tooltip>
              )}
            </ListItem>
            <Backdrop className={classes.backdrop} open={loading}>
              <CircularProgress color="inherit" />
            </Backdrop>
          </List>
        </Container>
      </Grid>
    </Grid>
  );
};

export default UserDetails;
