import Axios, { AxiosError, AxiosInstance } from "axios";
import { Store } from "redux";
import StoreManager from "../redux/store";
import { RootState } from "../redux/reducers";
import {
  actionLogin,
  actionLogout,
  actionRefreshEnd,
  actionRefreshStart,
  actionRemoveAccount,
  actionRemoveRefreshToken,
  actionRemoveToken,
  actionSetToken,
  AuthenticationActions,
} from "../redux/actions";
import AuthenticationApi from "./authentication";
import { RefreshAction } from "../redux/reducers/authentication.reducer";
import { API_HOST } from "../config";

// Create a separate axios instance
const axios = Axios.create({
  baseURL: API_HOST,
  headers: {
    "Content-Type": "application/json",
  },
});

const refreshToken = (
  error: AxiosError,
  store: Store<RootState>
): Promise<void> => {
  const state = store.getState();

  // If the state was already moving to authorize the client, ignore attempting to refresh
  if (state.auth.isInAuthorization) {
    return Promise.reject(error);
  }

  // If the state is currently already refreshing, return the same promise
  if (state.auth.isInRefresh) {
    return state.auth.refreshingCall as RefreshAction;
  }

  // If we have a refresh token available, use it in order to refresh the authentication
  if (state.auth.refreshToken !== null) {
    const refreshFn = AuthenticationApi.getTokenFromRefreshToken(
      state.auth.refreshToken
    )
      .then((token) => {
        store.dispatch(actionSetToken(token));
        // We do not have to set the account because the refreshing
        // should automatically yield the same account
        store.dispatch(actionLogin());
      })
      .catch((err) => {
        // Remove both tokens as the token is completely invalid
        store.dispatch(actionRemoveToken());
        store.dispatch(actionRemoveRefreshToken());
        store.dispatch(actionRemoveAccount());
        store.dispatch(actionLogout());
      })
      .finally(() => {
        store.dispatch(actionRefreshEnd());
      });
    store.dispatch(actionRefreshStart(refreshFn));

    // Return the same promise
    return refreshFn;
  }

  // This dispatch function is not properly typed so it won't accept a thunk
  store.dispatch(AuthenticationActions.logout() as any);

  // Pass back the original error
  return Promise.reject(error);
};

axios.interceptors.request.use((config) => {
  const store = StoreManager.getStore();
  const state = store.getState();
  config.headers["Authorization"] = `Bearer ${state.auth.idToken}`;
  return config;
});

axios.interceptors.response.use(
  (response) => response,
  (error) => {
    const status = error.response ? error.response.status : null;

    if (status === 401) {
      return refreshToken(error, StoreManager.getStore()).then(() => {
        const store = StoreManager.getStore();
        const state = store.getState();
        error.config.headers["Authorization"] = `Bearer ${state.auth.idToken}`;
        return axios.request(error.config);
      });
    }

    return Promise.reject(error);
  }
);

export function getAxios(): AxiosInstance {
  return axios;
}
