import React from "react";
import {
  createStyles,
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Theme,
} from "@material-ui/core";

import { WeekReportTrip } from "../../data/weekReport";
import { Utils } from "../../data/util";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    cell: {
      verticalAlign: "top",
    },
    importantCell: {
      fontWeight: "bold",
    },
    singleLineCell: {
      whiteSpace: "nowrap",
    },
  })
);

export const TravelExpenses = ({
  trips,
  expensePerKm,
}: {
  trips: WeekReportTrip[];
  expensePerKm: number;
}) => {
  const classes = useStyles();

  const totalDistance = trips.reduce(
    (sum, trip) => sum + trip.distance * trip.frequency,
    0
  );

  if (trips.length === 0) {
    return <div>No travel expenses</div>;
  }

  return (
    <TableContainer>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell className={classes.importantCell}>Frequency</TableCell>
            <TableCell className={classes.importantCell}>Route</TableCell>
            <TableCell className={classes.importantCell}>Distance</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {trips.map((trip, index) => (
            <TableRow key={index}>
              <TableCell className={classes.cell}>{trip.frequency} x</TableCell>
              <TableCell className={classes.cell}>
                {`${trip.from} > ${trip.to}`}
                {trip.twoWay ? ` > ${trip.from}` : ""}
              </TableCell>
              <TableCell
                className={`${classes.cell} ${classes.singleLineCell}`}
              >
                {`${Utils.formatDistance(trip.distance * trip.frequency)} km`}
              </TableCell>
            </TableRow>
          ))}
          <TableRow>
            <TableCell
              colSpan={2}
              className={`${classes.importantCell} ${classes.singleLineCell}`}
            >
              Total distance
            </TableCell>
            <TableCell
              className={`${classes.importantCell} ${classes.singleLineCell}`}
            >
              {`${Utils.formatDistance(totalDistance)} km`}
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell colSpan={2}>Expense per km</TableCell>
            <TableCell>€ {expensePerKm}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell
              colSpan={2}
              className={`${classes.importantCell} ${classes.singleLineCell}`}
            >
              Expenses covered
            </TableCell>
            <TableCell
              className={`${classes.importantCell} ${classes.singleLineCell}`}
            >
              {`€ ${Utils.formatAmount(totalDistance * expensePerKm)}`}
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  );
};
