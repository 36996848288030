import {
  Box,
  Dialog,
  DialogTitle,
  Divider,
  Grid,
  Link,
  List,
  ListItem,
  ListItemText,
  makeStyles,
  Paper,
  Theme,
  Typography,
} from "@material-ui/core";
import { useEffect, useState } from "react";
import { RouteComponentProps, withRouter } from "react-router-dom";
import NmbrsApi, { BusinessTripSyncStatus } from "../../../data/nmbrs";
import clsx from "clsx";
import React from "react";

const useStyles = makeStyles((theme: Theme) => ({
  statistics: {
    backgroundColor: "#f2f2f2",
  },
  mb: {
    marginBottom: "1em",
  },
  mr: {
    marginRight: "0.5em",
  },
  syncStatus: {
    marginRight: "0.5em",
    width: "15px",
    height: "15px",
    borderRadius: "50%",
    display: "inline-block",
  },
  syncSuccesful: {
    backgroundColor: "#27ae60",
  },
  syncPartial: {
    backgroundColor: "#f2c94c",
  },
  syncFailed: {
    backgroundColor: "#eb5757",
  },
  logLink: {
    marginLeft: "0.5em",
    color: "#0064a0",
    textDecoration: "underline",
  },
  tableContainer: {
    maxHeight: "100px",
    overflow: "auto",
  },
}));

export type SyncStatussesBlockProps = RouteComponentProps & {};
interface ErrorLogDialogProps {
  open: boolean;
  errorData: BusinessTripSyncStatus;
  onClose: () => void;
}

const ErrorLogDialog = (props: ErrorLogDialogProps) => {
  const { onClose, open, errorData } = props;

  const mapSyncStatusToText = (status: string): string => {
    if (status === "STATUS_PARTIAL") {
      return "Syncing partially completed, please see this log for details";
    } else if (status === "STATUS_SUCCESFUL") {
      return "Succesfully synced";
    } else {
      return "Syncing failed, please read this log for details";
    }
  };

  return (
    <Dialog onClose={onClose} aria-labelledby="simple-dialog-title" open={open}>
      <DialogTitle id="simple-dialog-title">
        {errorData.status ? mapSyncStatusToText(errorData.status) : ""}
      </DialogTitle>
      <List>
        <ListItem key="h1">
          <ListItemText
            primary={
              <Typography variant="subtitle2">
                Employees for which syncing failed:
              </Typography>
            }
          />
        </ListItem>
        {errorData.failedEmployees
          ? Object.keys(errorData.failedEmployees).map((value, key) => (
              <ListItem key={key}>
                <ListItemText
                  primary={errorData.failedEmployees[value]}
                  secondary={value}
                />
              </ListItem>
            ))
          : ""}
        <Divider key="dv1" />
        <ListItem key="h2">
          <ListItemText
            primary={<Typography variant="subtitle2">Error log:</Typography>}
          />
        </ListItem>
        {errorData.errors
          ? errorData.errors.map((msg, key) => (
              <ListItem key={key}>
                <ListItemText primary={msg} />
              </ListItem>
            ))
          : ""}
      </List>
    </Dialog>
  );
};

export const SyncStatussesBlock = withRouter(
  (props: SyncStatussesBlockProps) => {
    const classes = useStyles();

    const [syncStatusHistory, setSyncStatusHistory] = useState<
      BusinessTripSyncStatus[]
    >([]);
    const [historyErrorLogsOpen, setHistoryErrorLogsOpen] = useState<
      Map<string, boolean>
    >(new Map<string, boolean>([]));

    useEffect(() => {
      NmbrsApi.getAllBusinessTripSyncStatusses().then((statusses) => {
        setSyncStatusHistory(statusses);
        let historyOpenMapping = new Map<string, boolean>([]);
        statusses.forEach((status) => {
          historyOpenMapping.set(status.id, false);
        });
        setHistoryErrorLogsOpen(historyOpenMapping);
      });
    }, []);

    const openErrorHistoryErrorLog = (statusId: string) => {
      let mapCopy = new Map(historyErrorLogsOpen);
      mapCopy.set(statusId, true);
      setHistoryErrorLogsOpen(mapCopy);
    };

    const closeHistoryErrorLog = (statusId: string) => {
      let mapCopy = new Map(historyErrorLogsOpen);
      mapCopy.set(statusId, false);
      setHistoryErrorLogsOpen(mapCopy);
    };

    return (
      <Grid item xs={12}>
        <Paper className={classes.statistics} elevation={3}>
          <Box p="2em">
            <Typography variant="h6" component="h2">
              Business trips
            </Typography>
            <hr></hr>
            <Typography variant="body1">
              <b>Sync status</b>
            </Typography>
            <div className={classes.tableContainer}>
              <Grid direction="column" container>
                {syncStatusHistory.map((entry, index) => (
                  <React.Fragment key={index}>
                    <Grid item xs={12} container alignItems="center">
                      <Box
                        className={clsx(classes.syncStatus, {
                          [classes.syncSuccesful]:
                            entry.status === "STATUS_SUCCESFUL",
                          [classes.syncFailed]:
                            entry.status === "STATUS_FAILED",
                          [classes.syncPartial]:
                            entry.status === "STATUS_PARTIAL",
                        })}
                      />
                      <Link
                        className={classes.logLink}
                        href="#"
                        onClick={openErrorHistoryErrorLog.bind(this, entry.id)}
                      >
                        Period {entry.period}
                      </Link>
                      <span>&nbsp;-&nbsp;Week&nbsp;{entry.weekRange}</span>
                    </Grid>

                    <ErrorLogDialog
                      open={historyErrorLogsOpen.get(entry.id) ? true : false}
                      onClose={() => {
                        closeHistoryErrorLog(entry.id);
                      }}
                      errorData={entry}
                    />
                  </React.Fragment>
                ))}
              </Grid>
            </div>
          </Box>
        </Paper>
      </Grid>
    );
  }
);
