import { AuthenticationConstants } from "../constants";
import { History } from "../helpers";
import PrincipalApi, { Account } from "../../data/principal";
import AuthenticationApi, { Token } from "../../data/authentication";
import { Action } from "redux";
import { ThunkAction } from "redux-thunk";
import { RootState } from "../reducers";
import { RefreshAction } from "../reducers/authentication.reducer";

export const AuthenticationActions = {
  loginRedirect,
  loginAuthorization,
  loginRefresh,
  logout,
};

export interface AuthenticationAction extends Action<string> {}

export interface AuthenticationTokenAction extends AuthenticationAction {
  token: Token;
}

export interface AuthenticationAccountAction extends AuthenticationAction {
  account: Account;
}

export interface AuthenticationRefreshAction extends AuthenticationAction {
  fn: RefreshAction;
}

// Action types
export function actionAuthorizationStart(): AuthenticationAction {
  return { type: AuthenticationConstants.AUTHORIZATION_START };
}

export function actionAuthorizationEnd(): AuthenticationAction {
  return { type: AuthenticationConstants.AUTHORIZATION_END };
}

export function actionLogin(): AuthenticationAction {
  return { type: AuthenticationConstants.LOGIN };
}

export function actionLogout(): AuthenticationAction {
  return { type: AuthenticationConstants.LOGOUT };
}

export function actionSetToken(token: Token): AuthenticationTokenAction {
  return { type: AuthenticationConstants.TOKEN_SET, token };
}

export function actionRemoveToken(): AuthenticationAction {
  return { type: AuthenticationConstants.TOKEN_UNSET };
}

export function actionRemoveRefreshToken(): AuthenticationAction {
  return { type: AuthenticationConstants.TOKEN_REFRESH_UNSET };
}

export function actionSetAccount(
  account: Account
): AuthenticationAccountAction {
  return { type: AuthenticationConstants.ACCOUNT_SET, account };
}

export function actionRemoveAccount(): AuthenticationAction {
  return { type: AuthenticationConstants.ACCOUNT_UNSET };
}

export function actionRefreshStart(
  fn: RefreshAction
): AuthenticationRefreshAction {
  return { type: AuthenticationConstants.REFRESH_START, fn };
}

export function actionRefreshEnd(): AuthenticationAction {
  return { type: AuthenticationConstants.REFRESH_END };
}

// Action creators
function loginRedirect(
  redirectUri: string
): ThunkAction<void, RootState, void, AuthenticationAction> {
  return (dispatch, getstate) => {
    // Do not allow redirection to start if we're already authorizing
    // and have handed in the token
    if (getstate().auth.isInAuthorization) {
      return;
    }

    dispatch(actionAuthorizationStart());

    AuthenticationApi.getAuthorizationUri(redirectUri)
      .then((oidRedirectUri) => {
        // We cannot use the history here because this is an absolute url to another domain
        setTimeout(() => {
          window.location.href = oidRedirectUri.redirectUri;
        }, 1000);
      })
      .catch((err) => {
        // Clear data from the react state
        dispatch(actionAuthorizationEnd());

        console.error(err);
      });
  };
}

function loginAuthorization(
  authorizationCode: string,
  state: string,
  redirectUri: string
): ThunkAction<void, RootState, void, AuthenticationAction> {
  return (dispatch, getstate) => {
    if (!getstate().auth.isInAuthorization) {
      return;
    }

    AuthenticationApi.getTokenFromAuthorizationCode(
      authorizationCode,
      state,
      redirectUri
    )
      .then((token) => {
        // Assign the token to be used by the API class
        dispatch(actionSetToken(token));

        PrincipalApi.getAccount()
          .then((user) => {
            // Set the user and token in the react state
            dispatch(actionSetAccount(user));

            // Set that the user is now logged in
            dispatch(actionLogin());

            // Stop the authorization state
            dispatch(actionAuthorizationEnd());
          })
          .catch((err) => {
            // We have to revoke the tokens because they
            // were previously successfully issued
            dispatch(logout());

            console.error(err);
          })
          .finally(() => {
            // Stop the authorization state
            dispatch(actionAuthorizationEnd());

            // Redirect back to the authorize page
            History.push("/authorize");
          });
      })
      .catch((err) => {
        // Clear data from the react state
        dispatch(actionRemoveToken());
        dispatch(actionRemoveAccount());
        dispatch(actionLogout());
        dispatch(actionAuthorizationEnd());

        console.error(err);

        // Redirect back to the authorize page
        History.push("/authorize");
      });
  };
}

function loginRefresh(
  refreshToken: string
): ThunkAction<void, RootState, void, AuthenticationAction> {
  return (dispatch, _getstate) => {
    // Attempt to refresh the token
    const refreshFn = AuthenticationApi.getTokenFromRefreshToken(refreshToken)
      .then((token) => {
        // Set the token in the react state
        dispatch(actionSetToken(token));

        // Set that the user is now logged in
        dispatch(actionLogin());
      })
      .catch((err) => {
        // Clear data from the react state
        dispatch(actionRemoveToken());
        dispatch(actionRemoveAccount());
        dispatch(actionLogout());

        console.error(err);
      })
      .finally(() => {
        // Remove refresh call data
        dispatch(actionRefreshEnd());

        // Redirect back to the authorize page
        History.push("/authorize");
      });

    dispatch(actionRefreshStart(refreshFn));
  };
}

function logout(): ThunkAction<void, RootState, void, AuthenticationAction> {
  return (dispatch, _getstate) => {
    AuthenticationApi.revokeTokens()
      .then(() => {
        dispatch(actionRemoveToken());
        dispatch(actionRemoveRefreshToken());
        dispatch(actionRemoveAccount());
        dispatch(actionLogout());
      })
      .catch((err) => {
        console.error(err);
      })
      .finally(() => {
        History.push("/authorize");
      });
  };
}
