import { useEffect, useState, useMemo, useCallback } from "react";
import {
  createStyles,
  makeStyles,
  Theme,
  Container,
  Divider,
  IconButton,
  Typography,
} from "@material-ui/core";

import ProjectApi, { ProjectItem, ProjectType } from "../../../data/project";
import UserApi, { UserItem } from "../../../data/user";
import HolidayApi, { WeekHolidayList } from "../../../data/holiday";
import {
  useCurrentYearAndWeek,
  weekStartAndEnd,
  addWeeks,
  YearAndWeek,
  getWeekForDate,
  firstDayInWeekMoment,
} from "../../../hooks/dates";
import WeekReportApi, { Status, WeekReport } from "../../../data/weekReport";
import moment from "moment";
import { useSelector } from "react-redux";
import { RootState } from "../../../redux/reducers";
import { Account } from "../../../data/principal";
import { Title } from "../../../component/Title";
import { ChevronLeft, ChevronRight } from "@material-ui/icons";
import { WeekReportForm } from "./WeekReportForm";
import LoadingComponent from "../../../component/Authentication/Loading";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    header: {
      display: "flex",
      justifyContent: "space-between",
    },
  })
);

export const Dashboard = () => {
  const classes = useStyles();

  const [projects, setProjects] = useState<ProjectItem[]>([]);
  const [user, setUser] = useState<UserItem>();
  const account = useSelector<RootState>(
    (state) => state.auth.currentAccount
  ) as Account | null;

  const currentYearAndWeek = useCurrentYearAndWeek();
  const [yearAndWeek, setYearAndWeek] = useState<YearAndWeek | null>(null);
  const [disabledPrefill, setDisablePrefill] = useState<boolean>(false);
  const [year, weekNumber, weekStart, weekEnd] = useMemo(() => {
    const yw = yearAndWeek || currentYearAndWeek;
    const [weekStart, weekEnd] = weekStartAndEnd(yw);
    return yw
      ? [yw.year, yw.weekNumber, weekStart, weekEnd]
      : [null, null, weekStart, weekEnd];
  }, [currentYearAndWeek, yearAndWeek]);

  const [isSubmitted, setIsSubmitted] = useState(true);


  useEffect(() => {
    if (account !== null) {
      UserApi.getUser(account.id).then((res) => {
        setUser(res);
      });
    }
  }, [account]);

  useEffect(() => {
    if (year === null || weekNumber === null) {
      return;
    }

    let start = firstDayInWeekMoment({
      year: year!,
      weekNumber: weekNumber!,
    });
    let end = start.clone().day("Friday");
    ProjectApi.getUserProjects(start, end).then(setProjects);
  }, [year, weekNumber]);

  useEffect(() => {
    isPreviousReportAvailable().then((res) => {
      setDisablePrefill(res);
    });
  }, [disabledPrefill]);

  const isPreviousReportAvailable = async (): Promise<boolean> => {
    const now = moment();
    const lastWeek = now.clone().subtract(1, "week");
    const previousYear: number = lastWeek.year();
    const previousWeekNumber: number = getWeekForDate(lastWeek.toDate());

    try {
      return await WeekReportApi.getWeekReport(previousYear, previousWeekNumber)
        .then((previousReport) => {
          return false;
        })
        .catch((err) => {
          return true;
        });
    } catch (error) {
      return true;
    }
  };

  const prefill =
    year != null && weekNumber != null
      ? () => {
          const now: Date = new Date();
          const lastWeek: Date = new Date();
          lastWeek.setDate(now.getDate() - 7);
          const previousYear: number = lastWeek.getFullYear();
          const previousWeekNumber: number = getWeekForDate(lastWeek);
          WeekReportApi.getWeekReport(previousYear, previousWeekNumber).then(
            (previousWeekReport) => {
              WeekReportApi.getWeekReport(year, weekNumber).then(
                (currentWeekReport) => {
                  const ids = projects
                    .filter((project) =>
                      [
                        ProjectType.HOLIDAY,
                        ProjectType.SICKLEAVE,
                        ProjectType.VACATIONLEAVE,
                      ].includes(project.projectType)
                    )
                    .map((project) => project.projectId);
                  previousWeekReport.entries =
                    previousWeekReport.entries?.filter(
                      (entry) => !ids.includes(entry.projectId)
                    );
                  let start = firstDayInWeekMoment({
                    year: year,
                    weekNumber: weekNumber,
                  });
                  let end = start.clone().day("Friday");

                  HolidayApi.getHolidaysForWeek(start, end).then((holidays) => {
                    WeekReportApi.updateWeekReport({
                      entries:
                        fillPublicHolidays(previousWeekReport, holidays)
                          .clonedWeekReport.entries ?? [],
                      trips: previousWeekReport.trips ?? [],
                      weekReportId: currentWeekReport.weekReportId,
                    }).then(() => {
                      window.location.reload();
                    });
                  });
                }
              );
            }
          );
        }
      : () => {};

  const fillPublicHolidays = (
    weekReport: WeekReport,
    holidays: WeekHolidayList
  ): { holidaySet: boolean; clonedWeekReport: WeekReport } => {
    let holidayWorkHours = 0;
    let workingDaysInWeek = 5;
    const clonedWeekReport: WeekReport = Object.assign([], weekReport);

    if (!clonedWeekReport.hasOwnProperty("entries")) {
      clonedWeekReport.entries = [];
    }

    let holidayId = projects.find(
      (proj) => proj.projectType === ProjectType.HOLIDAY
    )?.projectId;

    if (holidays && holidays.holidays) {
      let holidaySet = false;
      holidays.holidays.forEach((element, index, arr) => {
        if (
          clonedWeekReport &&
          clonedWeekReport.entries &&
          moment.utc(element.date).isoWeekday() <= workingDaysInWeek
        ) {
          if (!holidayId) {
            return;
          }
          if (
            !clonedWeekReport.entries.find(
              (entry) => entry.weekDay === moment.utc(element.date).isoWeekday()
            )
          ) {
            clonedWeekReport.entries.push({
              weekDay: moment.utc(element.date).isoWeekday(),
              projectId: holidayId,
              hours: holidayWorkHours,
            });
            holidaySet = true;
          } else {
            let entry = clonedWeekReport.entries.find(
              (entry) => entry.weekDay === moment.utc(element.date).isoWeekday()
            );
            if (
              entry &&
              (entry.hours !== holidayWorkHours ||
                entry.projectId !== holidayId)
            ) {
              entry.hours = holidayWorkHours;
              entry.projectId = holidayId;
              holidaySet = true;
            }
          }
        }
      });

      if (clonedWeekReport && clonedWeekReport.entries) {
        let holEntries = clonedWeekReport.entries.filter(
          (entry) => entry.projectId === holidayId && entry.hours > 0
        );

        holEntries.forEach((ent) => {
          let foundHoliday = holidays.holidays.find(
            (hol) => moment.utc(hol.date).isoWeekday() === ent.weekDay
          );
          if (!foundHoliday) {
            ent.hours = 0;
            holidaySet = true;
          }
        });
      }

      return { holidaySet, clonedWeekReport };
    } else {
      let holidaySet = false;
      if (clonedWeekReport && clonedWeekReport.entries) {
        let holEntries = clonedWeekReport.entries.filter(
          (entry) => entry.projectId === holidayId && entry.hours > 0
        );

        holEntries.forEach((ent) => {
          ent.hours = 0;
          holidaySet = true;
        });
      }
      return { holidaySet: holidaySet, clonedWeekReport: clonedWeekReport };
    }
  };

  const updateWeekReportWithHolidays = (
    weekReport: WeekReport,
    holidays: WeekHolidayList
  ) => {
    let returnObject = fillPublicHolidays(weekReport, holidays);

    let holidaySet = returnObject.holidaySet;

    let clonedWeekReport = returnObject.clonedWeekReport;

    if (holidaySet && clonedWeekReport && clonedWeekReport.entries) {
      return WeekReportApi.updateWeekReport({
        entries: clonedWeekReport.entries ?? [],
        trips: clonedWeekReport.trips ?? [],
        weekReportId: clonedWeekReport.weekReportId,
      });
    } else {
      return Promise.resolve();
    }
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const getOrCreateAndGetWeekReport = useCallback(
    year !== null && weekNumber !== null
      ? () =>
          WeekReportApi.getWeekReport(year, weekNumber)
            .then((weekReport) => {
              if (weekReport.status === Status.Draft) {
                let start = firstDayInWeekMoment({
                  year: year,
                  weekNumber: weekNumber,
                });
                let end = start.clone().day("Friday");

                return HolidayApi.getHolidaysForWeek(start, end).then(
                  (holidays) => {
                    return updateWeekReportWithHolidays(
                      weekReport,
                      holidays
                    )?.then(() => {
                      return WeekReportApi.getWeekReport(year, weekNumber).then(
                        (weekReport) => {
                          setIsSubmitted(
                            weekReport.status === Status.Submitted
                          );
                          return weekReport;
                        }
                      );
                    });
                  }
                );
              } else {
                return weekReport;
              }
            })
            .catch(async (error) => {
              if (error.response && error.response.status === 404) {
                await WeekReportApi.createWeekReport({ year, weekNumber });
                const wr = await WeekReportApi.getWeekReport(year, weekNumber);
                let start = firstDayInWeekMoment({
                  year: year,
                  weekNumber: weekNumber,
                });
                let end = start.clone().day("Friday");

                const holidays = await HolidayApi.getHolidaysForWeek(
                  start,
                  end
                );
                await updateWeekReportWithHolidays(wr, holidays);
                const wr2 = await WeekReportApi.getWeekReport(year, weekNumber);
                setIsSubmitted(false);
                return wr2;
              }
              throw error;
            })
      : () => {
          let weekReport: WeekReport = {
            status: Status.Draft,
            weekReportId: "",
            entries: [],
            trips: [],
          };
          return Promise.resolve(weekReport);
        },
    [year, weekNumber]
  );

  if (!user) return <LoadingComponent />;

  const changeYearAndWeek = (count: number) => {
    if (year != null && weekNumber != null) {
      setYearAndWeek(addWeeks({ year, weekNumber }, count));
    }
  };

  const isWeeksAhead =
    !currentYearAndWeek ||
    (year != null && year > currentYearAndWeek.year) ||
    (weekNumber != null &&
      year === currentYearAndWeek.year &&
      weekNumber > currentYearAndWeek.weekNumber);

  const currentWeek =
    !currentYearAndWeek ||
    (weekNumber !== null &&
      year !== null &&
      year === currentYearAndWeek.year &&
      weekNumber === currentYearAndWeek.weekNumber);

  return (
    <Container>
      <div className={classes.header}>
        <div>
          <Title>Week {weekNumber}</Title>
          <Typography variant="subtitle1">
            {weekStart} to {weekEnd}
          </Typography>
        </div>
        <div>
          <IconButton color="primary" onClick={() => changeYearAndWeek(-1)}>
            <ChevronLeft id="chevron-left" />
          </IconButton>
          <IconButton color="primary" onClick={() => changeYearAndWeek(1)}>
            <ChevronRight id="chevron-right" />
          </IconButton>
        </div>
      </div>
      <Divider />
      <WeekReportForm
        projects={projects}
        contractedHours={user.contractedHours || 0} // Default value is now zero-hours. For non-zero hour contracts this does not matter
        // as this field is required when creating and updating users.
        getWeekReport={getOrCreateAndGetWeekReport}
        weekStart={weekStart}
        year={year}
        weekNumber={weekNumber}
        showPrefill={currentWeek && !isSubmitted && projects.length > 0}
        disabledPrefill={disabledPrefill}
        isWeeksAhead={isWeeksAhead}
        prefillFunction={prefill}
        userStartDate={user.employmentStartDate}
        userEndDate={user.employmentEndDate}
      />
    </Container>
  );
};
