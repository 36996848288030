import React, { useEffect, useMemo, useState } from "react";
import {
  createStyles,
  makeStyles,
  Checkbox,
  Table,
  TableCell,
  TableHead,
  TableBody,
  TableRow,
  Theme,
  IconButton,
} from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import { Add as AddIcon, Delete as DeleteIcon } from "@material-ui/icons";

import { Section } from "../../../component/Section";
import { BusinessTrip } from "../../../data/weekReport";
import { Dropdown } from "../../../component/Dropdown";
import GoogleMapsApi from "../../../data/googleMaps";
import { Utils } from "../../../data/util";
import { Action } from "./reducer";
import { AddressType, NamedAddress } from "../../../data/address";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    title: {
      fontWeight: "bold",
    },
    link: {
      textDecoration: "underline",
    },
    header: {
      display: "flex",
      justifyContent: "space-between",
      marginTop: "20px",
    },
    singleLineText: {
      whiteSpace: "nowrap",
    },
    trashbin: {
      cursor: "pointer",
    },
  })
);

const amountOptions = {
  "1": "1",
  "2": "2",
  "3": "3",
  "4": "4",
  "5": "5",
};

// TODO get from read model
const distance = (trip: BusinessTrip): Promise<number> => {
  if (
    trip.placeIdFrom == null ||
    trip.placeIdTo == null ||
    trip.placeIdFrom === "" ||
    trip.placeIdTo === ""
  ) {
    return new Promise<number>((res, err) => {
      res(0);
    });
  }

  return GoogleMapsApi.getDistances({
    originPlaceIds: [trip.placeIdFrom, trip.placeIdTo],
    destinationPlaceIds: [trip.placeIdTo, trip.placeIdFrom],
  }).then((res) => {
    let distanceFrom: number = 0;
    let distanceTo: number = 0;

    distanceFrom = res.distances
      .find((row) => row.originId === trip.placeIdFrom)!
      .destinationDistances.find(
        (destination) => destination.destinationId === trip.placeIdTo
      )!.distance;

    distanceTo = res.distances
      .find((row) => row.originId === trip.placeIdTo)!
      .destinationDistances.find(
        (destination) => destination.destinationId === trip.placeIdFrom
      )!.distance;

    return trip.frequency * (distanceFrom + (trip.twoWay ? distanceTo : 0));
  });
};

interface BusinessTripsProps {
  trips: BusinessTrip[];
  disabled: boolean;
  dispatch: (action: Action) => void;
  addresses: NamedAddress[];
  expensePerKm: number;
}

export const BusinessTrips = ({
  trips,
  disabled,
  dispatch,
  addresses,
  expensePerKm,
}: BusinessTripsProps) => {
  const classes = useStyles();

  const [rowHoverIndex, setRowHoverIndex] = useState<number | null>(null);

  const [originAddressKey, setOriginAddressKey] = useState<string[]>([]);

  const [destinationAddressKey, setDestinationAddressKey] = useState<string[]>(
    []
  );

  const handleTripAdded = (): void => {
    dispatch({
      type: "tripAdded",
      trip: {
        addressNameFrom: "",
        linkedPlaceIdFrom: "",
        linkedPlaceIdFromType: AddressType.HOME,
        addressNameTo: "",
        linkedPlaceIdTo: "",
        linkedPlaceIdToType: AddressType.CUSTOMER,
        frequency: 1,
        placeIdFrom: "",
        placeIdTo: "",
        twoWay: false,
      },
    });
  };

  const handleTripChange = (index: number, trip: BusinessTrip) => {
    dispatch({ type: "tripUpdated", index, trip });
  };

  const handleTripRemoved = (index: number) => {
    dispatch({ type: "tripRemoved", index });
  };

  const [
    translatedPlaceIdsToSelectedAddresses,
    setTranslatedPlaceIdsToSelectedAddresses,
  ] = useState<boolean>(false);

  const placeIdToAddressKey = useMemo(() => {
    return addresses.reduce((map, address) => {
      map[address.googleAddressId] = JSON.stringify(address);
      return map;
    }, {} as { [key: string]: string });
  }, [addresses]);

  useEffect(() => {
    if (!translatedPlaceIdsToSelectedAddresses && addresses.length > 0) {
      setOriginAddressKey(
        trips.reduce((keys, trip) => {
          if (trip.placeIdFrom !== null) {
            keys.push(placeIdToAddressKey[trip.placeIdFrom]);
          }
          return keys;
        }, [] as string[])
      );
      setDestinationAddressKey(
        trips.reduce((keys, trip) => {
          if (trip.placeIdTo !== null) {
            keys.push(placeIdToAddressKey[trip.placeIdTo]);
          }
          return keys;
        }, [] as string[])
      );
      setTranslatedPlaceIdsToSelectedAddresses(true);
    }
  }, [
    addresses,
    trips,
    placeIdToAddressKey,
    translatedPlaceIdsToSelectedAddresses,
  ]);
  const addressNameToKey = (
    exclude: string | null
  ): { [key: string]: string } => {
    const ps = exclude
      ? addresses.filter(
          (address) => address.googleAddressId !== addressKeyToId()[exclude]
        )
      : addresses;
    return ps.reduce((map, address) => {
      if (address.name) {
        map[JSON.stringify(address)] = address.name;
      }
      return map;
    }, {} as { [key: string]: string });
  };

  const addressKeyToId = (): { [key: string]: string } => {
    return addresses.reduce((map, address) => {
      map[JSON.stringify(address)] = address.googleAddressId;
      return map;
    }, {} as { [key: string]: string });
  };

  const addressKeyToAddressId = (): { [key: string]: string } => {
    return addresses.reduce((map, address) => {
      map[JSON.stringify(address)] = address.addressId;
      return map;
    }, {} as { [key: string]: string });
  };
  const addressKeyToAddressName = (): { [key: string]: string } => {
    return addresses.reduce((map, address) => {
      map[JSON.stringify(address)] = address.name;
      return map;
    }, {} as { [key: string]: string });
  };
  const addressKeyToType = (): { [key: string]: AddressType } => {
    return addresses.reduce((map, address) => {
      map[JSON.stringify(address)] = address.addressType;
      return map;
    }, {} as { [key: string]: AddressType });
  };

  const distances = useMemo(() => {
    let distanceApiCalls: Promise<number>[] = [];
    const distances: number[] = [];
    trips.forEach((trip, index) => (distanceApiCalls[index] = distance(trip)));
    Promise.all(distanceApiCalls).then((res) => {
      res.forEach((res, index) => {
        distances[index] = res;
      });
    });
    return distances;
  }, [trips]);

  const total = (): number => {
    return distances.reduce((sum, d) => (sum += d), 0);
  };

  return (
    <div>
      <div className={classes.header}>
        <Section>Business Trips</Section>
        {!disabled && (
          <IconButton
            id="business-trip-add-button"
            color="primary"
            onClick={handleTripAdded}
          >
            <AddIcon />
          </IconButton>
        )}
      </div>
      {trips.length === 0 && (
        <Alert severity="info">
          <div className={classes.title}>No business trips</div>
          {!disabled && (
            <div>
              <span className={classes.link} onClick={handleTripAdded}>
                Add a trip
              </span>{" "}
              to start registering your travel expenses
            </div>
          )}
        </Alert>
      )}
      {trips.length > 0 && (
        <Table>
          <TableHead>
            <TableRow>
              <TableCell></TableCell>
              <TableCell id="business-trip-from" className={classes.title}>
                From
              </TableCell>
              <TableCell id="business-trip-to" className={classes.title}>
                To
              </TableCell>
              <TableCell id="business-trip-return" className={classes.title}>
                Return
              </TableCell>
              <TableCell></TableCell>
              <TableCell></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {trips.map((trip, index) => (
              <TableRow
                key={index}
                onMouseEnter={() => setRowHoverIndex(index)}
                onMouseLeave={() => setRowHoverIndex(null)}
              >
                <TableCell id={`business-trip-frequency-dropdown-${index}`}>
                  <Dropdown
                    disabled={disabled}
                    value={"" + trip.frequency}
                    values={amountOptions}
                    onChange={(value) =>
                      handleTripChange(index, { ...trip, frequency: +value })
                    }
                    small
                    maxWidth="4em"
                  />
                </TableCell>
                <TableCell id={`business-trip-address-from-dropdown-${index}`}>
                  <Dropdown
                    disabled={disabled}
                    value={originAddressKey[index]}
                    values={addressNameToKey(destinationAddressKey[index])}
                    onChange={(originAddressKey) => {
                      handleTripChange(index, {
                        ...trip,
                        placeIdFrom: addressKeyToId()[originAddressKey],
                        linkedPlaceIdFromType:
                          addressKeyToType()[originAddressKey],
                        linkedPlaceIdFrom:
                          addressKeyToAddressId()[originAddressKey],
                        addressNameFrom:
                          addressKeyToAddressName()[originAddressKey],
                      });
                      setOriginAddressKey((state) => {
                        let list: string[] = [...state];
                        list[index] = originAddressKey;
                        return list;
                      });
                    }}
                    small
                  />
                </TableCell>
                <TableCell id={`business-trip-address-to-dropdown-${index}`}>
                  <Dropdown
                    disabled={disabled}
                    value={destinationAddressKey[index]}
                    values={addressNameToKey(originAddressKey[index])}
                    onChange={(destinationAddressKey) => {
                      handleTripChange(index, {
                        ...trip,
                        placeIdTo: addressKeyToId()[destinationAddressKey],
                        linkedPlaceIdToType:
                          addressKeyToType()[destinationAddressKey],
                        linkedPlaceIdTo:
                          addressKeyToAddressId()[destinationAddressKey],
                        addressNameTo:
                          addressKeyToAddressName()[destinationAddressKey],
                      });
                      setDestinationAddressKey((state) => {
                        let list: string[] = [...state];
                        list[index] = destinationAddressKey;
                        return list;
                      });
                    }}
                    small
                  />
                </TableCell>
                <TableCell>
                  <Checkbox
                    id={`business-trip-check-box-${index}`}
                    disabled={disabled}
                    checked={trip.twoWay}
                    onChange={(event) =>
                      handleTripChange(index, {
                        ...trip,
                        twoWay: event.target.checked,
                      })
                    }
                  />
                </TableCell>
                <TableCell className={classes.singleLineText}>
                  {`${Utils.formatDistance(distances[index] ?? 0)} km`}
                </TableCell>
                <TableCell>
                  {disabled ? (
                    ``
                  ) : (
                    <DeleteIcon
                      id={`business-trip-delete-icon-${index}`}
                      className={classes.trashbin}
                      style={rowHoverIndex === index ? {} : { opacity: 0 }}
                      onClick={() => {
                        setOriginAddressKey((state) => {
                          let list: string[] = [...state];
                          list.splice(index, 1);
                          return list;
                        });
                        setDestinationAddressKey((state) => {
                          let list: string[] = [...state];
                          list.splice(index, 1);
                          return list;
                        });
                        handleTripRemoved(index);
                      }}
                    />
                  )}
                </TableCell>
              </TableRow>
            ))}
            <TableRow>
              <TableCell colSpan={4}>Total distance</TableCell>
              <TableCell
                className={classes.singleLineText}
              >{`${Utils.formatDistance(total())} km`}</TableCell>
              <TableCell></TableCell>
            </TableRow>
            <TableRow>
              <TableCell colSpan={4}>Expense per km</TableCell>
              <TableCell>{`€ ${expensePerKm}`}</TableCell>
              <TableCell></TableCell>
            </TableRow>
            <TableRow>
              <TableCell className={classes.title} colSpan={4}>
                Expenses covered
              </TableCell>
              <TableCell
                className={`${classes.title} ${classes.singleLineText}`}
              >
                {`€ ${Utils.formatAmount(expensePerKm * total())}`}
              </TableCell>
              <TableCell></TableCell>
            </TableRow>
          </TableBody>
        </Table>
      )}
    </div>
  );
};
