import { format, parse, parseISO } from "date-fns";

const formatDistance = (distance: number): string => {
  return distance % 1 !== 0 ? distance.toFixed(1) : distance.toString();
};

const formatAmount = (amount: number): string => {
  return amount.toFixed(2).replace(".", ",");
};

const parseDate = (str: string | undefined | null): Date | null => {
  return str ? parseISO(str) : null;
};

const parseDateTime = (date: string, time?: string): Date | null => {
  return date
    ? parse(
        date + " " + (time ? time : "00:00:00:000000000"),
        "yyyy-MM-dd HH:mm:ss:SSSSSSSSS",
        new Date()
      )
    : null;
};

const formatDate = (date: Date | null): string => {
  return date ? format(date, "yyyy-MM-dd") : "";
};

const viewDate = (date: Date | null): string => {
  return date ? format(date, "dd-MM-yyyy") : "";
};

const viewDateTime = (dateTime: Date | null): string => {
  return dateTime ? format(dateTime, "yyyy-MM-dd HH:mm:ss:SSSSSSSSS") : "";
};

export const Utils = {
  formatDistance,
  formatAmount,
  parseDate,
  parseDateTime,
  formatDate,
  viewDate,
  viewDateTime,
};
